import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VBtn,{on:{"click":function($event){return _vm.$router.replace('/home')}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1)],1)],1),_c(VRow,[_c(VCol,[_c('h1',[_vm._v("Enter Catlog Number")])])],1),_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":"","outlined":""}},[_c(VCardTitle,{staticClass:"text-center"},[_c('h2')]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VSelect,{attrs:{"items":_vm.formats,"label":"Format"},model:{value:(_vm.format),callback:function ($$v) {_vm.format=$$v},expression:"format"}})],1),_c(VCol,{attrs:{"cols":"8"}},[_c(VTextField,{attrs:{"label":"Catlog or Name","outlined":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_vm._v(" - ")],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.search()}}},[_vm._v("Find Item")])],1),_c(VCol,[_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'Release', query: { mode: 'new' } })}}},[_vm._v("Create Without Catlog")])],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.releases,"loading":_vm.loading,"items-per-page":10},on:{"click:row":_vm.goToRelease},scopedSlots:_vm._u([{key:"item.cover_image",fn:function({ item }){return [_c(VImg,{attrs:{"src":item.cover_image,"alt":item.title,"width":"100"}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }