<template>
    <v-container class="main-container">

        <v-row>
            <v-col class="top-heading">Welcome To CalcuttaRecords</v-col>
        </v-row>
        <div v-if="user.role == 'manager' || user.role == 'agent'">
            <v-row>
                <v-col>
                    <v-card outlined>
                        <v-card-text>
                            <h2>{{ totalCount }}</h2><br />
                            Total Listing
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card outlined>
                        <v-card-text>
                            <h2>{{ pendingCount }}</h2><br />
                            Unpublished
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card outlined>
                        <v-card-text>
                            <h2> {{ publishedCount }}</h2><br />
                            Published
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>

            <v-row class="d-flex align-center justify-space-between flex-wrap ">
                <v-col class="d-flex align-center" lg="4" md="4" sm="12">
                    <v-btn @click="$router.push('/start')">New Listing</v-btn>
                    <v-btn class="ml-4" @click="$router.push('/other')">Other Listing</v-btn>
                </v-col>
                <v-col class="d-flex align-center justify-space-between flex-wrap" lg="8" md="8" sm="12">
                    <v-text-field class="mr-2 search-query" v-model="sku" label="Enter SKU"></v-text-field>

                    <v-btn @click="openSku"> Check & List</v-btn>
                </v-col>
            </v-row>

            <v-row>

                <v-col>


                    <v-data-table :headers="headers" :items="listings" :items-per-page.sync="itemsPerPage" :page.sync="page"
                        :server-items-length="totalListings" loading-text="Please wait" :loading="loading"
                        class="elevation-1" hide-default-footer>

                        <template v-slot:item.title="{ item }">
                            <span class="product-title" @click="rowClicked(item)">TT{{ item.title }}</span>
                        </template>

                        <template v-slot:top>
                            <v-text-field v-model="searchQuery" label="Search by SKU or Name" append-icon="mdi-magnify"
                                class="mx-4"></v-text-field>
                        </template>

                        <template v-slot:item.images="{ item }">

                            <img :src="`${ENDPOINT}/uploads/compressed/${item.images[0]}`" alt="Listing Image" height="50"
                                v-if="item.images && item.images.length">

                        </template>

                        <template v-slot:item.published="{ item }">
                            <v-chip v-if="item.published" color="green">Pub <v-btn text
                                    @click="openUrl(item.publishedUrl)"><v-icon>mdi-link</v-icon></v-btn></v-chip>
                            <v-chip v-else color="grey">Draft</v-chip>
                        </template>

                        <template v-slot:item.etsyPublished="{ item }">
                            <v-chip :color="item.etsyPublished ? 'green' : 'red'" @click="handleEtsyPublish(item)">
                                {{ item.etsyPublished ? 'Published' : 'Not Published' }}
                            </v-chip>
                        </template>

                    </v-data-table>
                    <div class="d-flex align-center justify-end my-3">
                        <v-pagination v-model="page" :length="totalPages" :total-visible="5"></v-pagination>
                    </div>

                </v-col>

            </v-row>
        </div>


    </v-container>
</template>
<script>

import store from "@/store/store";
import axios from "axios"
export default {
    data() {
        return {
            ENDPOINT: process.env.VUE_APP_ENDPOINT,
            sku: "",
            searchQuery: '',
            listings: [],
            totalListings: 0,
            page: 1,
            itemsPerPage: 10,
            totalCount: 0,
            pendingCount: 0,
            publishedCount: 0,
            headers: [
                { text: 'Title', value: 'title' },
                { text: 'Price', value: 'listPrice' },
                { text: 'Image', value: 'images' },
                { text: 'Status', value: 'published' },
                { text: 'Etsy', value: 'etsyPublished' },
            ],
            loading: false,
            user: store.getters.user,
            totalPages: 1
        }
    },

    watch: {

        page() {
            this.fetchListings();
        },
        itemsPerPage() {
            this.fetchListings();
        },
        searchQuery: {
            handler() {
                this.page = 1; // Reset to the first page when a new search term is entered
                this.fetchListings();
            },
            immediate: false, // Don't run the handler right away
            deep: false, // Don't deep-watch the object
        },
    },
    async created() {
        this.fetchListings();
        console.log("Userrrrrr: ", this.user);
        try {
            const response = await axios.get(this.ENDPOINT + '/listings-count');
            this.totalCount = response.data.totalCount;
            this.pendingCount = response.data.pendingCount;
            this.publishedCount = response.data.publishedCount;
        } catch (error) {
            console.error("Error fetching counts:", error);
        }
    },
    methods: {
        async handleEtsyPublish(item) {
            console.log(item)
            this.loading = true;
            if (!item.etsyPublished) {
                const price = window.prompt("Enter pricing");
                console.log("Rpice ", price)
                if (price == null || price == "") {
                    this.loading = false;
                    return;
                }
                // Post the data to Etsy
                try {
                    const response = await axios.post(this.ENDPOINT + '/postToEtsy', { price, listingId: item._id });

                    this.fetchListings()
                    this.loading = false;
                    if (response.status === 200) {
                        // Update the etsyPublished status in your local data, store the Etsy listing URL, and update on the server
                        alert("updated to etsy")
                    } else {
                        alert('Error posting to Etsy');
                    }
                } catch (error) {
                    console.log(error)
                    this.loading = false;
                    alert('Error posting to Etsy');
                }
            } else {
                window.open(item.etsyListingUrl, "_blank")
            }
        },
        openUrl(url) {
            window.open(url, "_blank")
        },
        async fetchListings() {
            try {
                this.loading = true;
                const response = await axios.get(this.ENDPOINT + '/listings', {
                    params: {
                        page: this.page - 1,  // 0-indexed for the backend
                        limit: this.itemsPerPage,
                        search: this.searchQuery
                    },
                });
                this.listings = response.data.listings;
                this.totalListings = response.data.totalCount;
                this.totalPages = response.data.totalPages;
                console.log("total pages listing: ", response.data.totalPages);
            } catch (error) {
                console.error('Error fetching listings:', error);
            } finally {
                this.loading = false;
            }
        },


        openSku() {
            this.$router.replace('/release/edit/' + this.sku)
        },
        async rowClicked(item) {
            console.log("Item: ", item);
            // const resp = await axios.get(this.ENDPOINT + '/get-products');
            // console.log("response product woocommerce: ", resp.data);
            if (!item.productType) {
                this.$router.push({ path: '/release/edit/' + item.sku });
            } else {
                if (item.productType == "vinyl" || item.productType == "cd" || item.productType == "cassette") {
                    this.$router.push({ path: '/release/edit/' + item.sku });

                } else {

                    this.$router.push({ path: '/releaseother/edit/' + item.sku, query: { format: item.productType } });
                }

            }
        },

    }
}


</script>
<style scoped>
.main-container {
    max-width: 95vw;
}

.search-query {
    min-width: 20rem;
}

.product-title:hover {
    cursor: pointer;
}

.top-heading {
    font-size: 2rem;
    font-weight: 500;
}
</style>