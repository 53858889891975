<template>
    <v-container>

        <v-text-field v-model="orderId" label="Enter Order ID"></v-text-field>
        <v-btn @click="fetchOrder">FIND</v-btn>

        <v-card class="tracking-container" v-if="order && !order.orders">
            <!-- Display order details here -->
            <div class="order-status">
                <p>Current Order status: </p>
                <v-chip class="chip-red" v-if="order.status == 'shipped' || order.status == 'completed'"
                    color="green">{{
                        order.status }}</v-chip>
                <v-chip class="chip-red" v-else color="red" elevation="5">{{ order.status }}</v-chip>
            </div>
            <div class="warn-resolved" v-if="order.status == 'resolved'">
                <p>Resolved order status indicates that the shipping information was lost or misplaced and couldn't be
                    retrieved for some reason.</p>
            </div>

            <div v-if="order">
                <!-- Shipping Info Form -->
                <v-text-field v-model="trackingId" label="Tracking ID"></v-text-field>
                <v-select :items="['dtdc', 'indiapost', 'delhivery', 'other']" v-model="shippingCompany"
                    label="Shipping Company" @change="checkOtherCompany"></v-select>
                <v-text-field v-if="isOtherCompany" v-model="otherShippingCompany"
                    label="Other Shipping Company"></v-text-field>
                <v-text-field v-model="shippingCost" label="Cost" type="number"></v-text-field>

                <v-text-field v-model="trackingLink" label="Tracking Link" type="text"></v-text-field>

                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="shippingDate" label="Shipping Date" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="shippingDate" @input="menu2 = false"></v-date-picker>
                </v-menu>
                <v-card-actions class="pa-0">
                    <v-btn @click="updateShippingInfo">Update Shipping Info</v-btn>
                    <v-btn v-if="order && order.status != 'shipped' && order.status != 'resolved'" color="warning"
                        @click="openConfirm">Mark Resolved</v-btn>
                    <v-btn v-if="order && order.status == 'shipped'" class="send-button" color="deep-purple"
                        @click="sendEmailNM">
                        <v-icon>mdi-email-arrow-right-outline</v-icon>
                        Send On Mail
                    </v-btn>
                    <v-btn v-if="order && order.status == 'shipped'" class="send-button" color="light-green darken-1"
                        @click="sendWhatsapp">
                        <v-icon>mdi-whatsapp</v-icon>
                        Send On whatsapp
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
        <!-- <div v-if="order && order.orders">
            Please enter correct order id
        </div> -->
        <v-snackbar v-model="snackbar" :timeout="timeout" absolute outlined color="red" top>
            {{ snackbarText }}
        </v-snackbar>

        <v-dialog v-model="isDialogOpen" persistent max-width="400">
            <v-card class="d-flex align-center justify-center pa-7">

                <v-progress-circular :size="50" color="purple" indeterminate></v-progress-circular>
                <v-card-title class="text-h5">Searching...</v-card-title>
            </v-card>
        </v-dialog>
        <v-dialog v-model="confirmDialog" persistent max-width="400">
            <v-card class="d-flex flex-column align-center justify-center pa-5">

                <!-- <v-progress-circular :size="50" color="purple" indeterminate></v-progress-circular> -->
                <v-card-suntitle class="confirm-text">Are you sure you want to mark it resolved?</v-card-suntitle>
                <v-card-actions>
                    <v-btn color="primary" plain @click="updateToResolved(order)">Confirm</v-btn>
                    <v-btn color="error" plain @click="closeConfirm">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isDialogTwoOpen" persistent max-width="400">
            <v-card class="d-flex align-center justify-center pa-7">

                <v-progress-circular :size="50" color="purple" indeterminate></v-progress-circular>
                <v-card-title class="text-h5">Sending Email...</v-card-title>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isDialogThreeOpen" persistent max-width="400">
            <v-card class="d-flex align-center justify-center pa-7">

                <v-progress-circular :size="50" color="purple" indeterminate></v-progress-circular>
                <v-card-title class="text-h5">Updating...</v-card-title>
            </v-card>
        </v-dialog>

        <!-- ! Card to send whatsapp message and email -->
        <v-card v-if="order && order.status == 'shipped'" class="pa-3 my-4">
            <v-card-title class="px-0">Tracking Message</v-card-title>
            <v-textarea v-model="trackMessage" rows="10" readonly></v-textarea>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from "moment-timezone"
export default {
    data() {
        return {
            ENDPOINT: process.env.VUE_APP_ENDPOINT,
            orderId: '',
            order: null,
            trackingId: '',
            shippingCompany: '',
            otherShippingCompany: '',
            shippingCost: 0,
            shippingDate: "",
            isOtherCompany: false,
            menu2: false,
            snackbar: false,
            timeout: 2000,
            snackbarText: '',
            confirmDialog: false,
            trackingLink: '',
            linkArray: [
                {
                    trackingCompany: 'dtdc',
                    link: 'https://www.dtdc.in/tracking.asp'
                },
                {
                    trackingCompany: 'delhivery',
                    link: 'https://www.delhivery.com/tracking'
                },
                {
                    trackingCompany: 'bluedart',
                    link: 'https://www.bluedart.com/tracking'
                },
            ],
            trackMessage: '',
            isDialogOpen: false,
            isDialogTwoOpen: false,
            isDialogThreeOpen: false
        };
    },
    methods: {

        checkOtherCompany() {
            this.isOtherCompany = this.shippingCompany === 'other';
            const companyObject = this.linkArray.find(item => item.trackingCompany === this.shippingCompany);
            if (companyObject) {
                this.trackingLink = companyObject.link;
            } else {
                this.trackingLink = '';
            }
        },

        async fetchOrder() {
            if (this.order) {
                this.trackingId = "";
                this.shippingCompany = "";
                this.isOtherCompany = false;
                this.shippingCost = 0;
                this.trackMessage = '';
                this.trackingLink = '';
                this.order = null;
            }
            this.isDialogOpen = true;
            try {
                const response = await axios.get(this.ENDPOINT + `/orders/${this.orderId}`);
                this.order = response.data;
                // console.log(JSON.stringify(this.order.meta_data));
                console.log("find order data: ", this.order);
                // console.log("order length: ", this.order.length);
                // if user try to put order field blank and try to search then this will come
                if (this.order && this.order.orders) {
                    this.snackbar = true;
                    this.snackbarText = "Please enter correct order id";
                    return;
                }
                // If only one order is there with proper orderID and it is shipped then this part of code will perform
                // if (this.order.status) {
                // Assume the shipping data is in the order's meta_data
                const shippingMetaData = this.order.meta_data;
                this.trackingId = shippingMetaData.find(data => data.key === 'tracking_id')?.value || '';
                this.shippingCompany = shippingMetaData.find(data => data.key === 'shipping_company')?.value || '';
                this.shippingCost = shippingMetaData.find(data => data.key === 'shipping_cost')?.value || 0;
                this.shippingDate = shippingMetaData.find(data => data.key === 'shipping_date')?.value || '';
                this.trackingLink = shippingMetaData.find(data => data.key === 'tracking_link')?.value || '';
                this.trackMessage = `Dear ${this.order.shipping.first_name},

Thank you for choosing Calcutta Records Company! We are excited to inform you that your order has been shipped and is on its way to you. Here are the tracking details for your reference:

Tracking Link: https://track.calcuttarecords.com/${this.order.id}

Consignment Number: ${this.trackingId}

You can track your package's progress by visiting the tracking website and entering your tracking number. If you have any questions or need further assistance, please don't hesitate to reach out to our customer support team at calcuttarecordcompany@gmail.com.

We appreciate your business and look forward to delivering your order to you soon.

Best regards,
Calcutta Records Company Team
            `
                // }

            } catch (error) {
                console.error('Error fetching order:', error);
                this.snackbar = true;
                this.snackbarText = `${error.response.data}. Please check order id`;
                // Handle error (e.g., order not found or server error)
            }
            this.isDialogOpen = false;
        },
        async updateToResolved(order) {
            console.log("order id: ", order.id);
            this.confirmDialog = false;
            this.isDialogThreeOpen = true;
            const resp = await axios.post(this.ENDPOINT + `/update-order-status`, {
                orderId: order.id,
                status: "resolved"
            });
            this.fetchOrder();
            this.isDialogThreeOpen = false;
        },
        async closeConfirm() {
            this.confirmDialog = false;
        },
        async openConfirm() {
            this.confirmDialog = true;
        },
        async updateShippingInfo() {
            try {
                const response = await axios.post(this.ENDPOINT + `/orders/${this.orderId}/update-shipping`, {
                    trackingId: this.trackingId,
                    shippingCompany: this.isOtherCompany ? this.otherShippingCompany : this.shippingCompany,
                    shippingCost: this.shippingCost,
                    shippingDate: this.shippingDate,
                    trackingLink: this.trackingLink
                });
                console.log("Response of shipping: ", response.data);
                const mailResponse = await axios.post(this.ENDPOINT + `/send-email?status=shipped`, {
                    orderId: this.order.id,
                    trackingId: this.trackingId,
                    first_name: this.order.shipping.first_name,
                    email: this.order.shipping.email ? this.order.shipping.email : this.order.billing.email
                });
                const whatsappResponse = await axios.post(this.ENDPOINT + `/send-whatsapp?status=shipped`, {
                    ...this.order,
                    trackingId: this.trackingId
                });

                console.log("whatsappResponse: ", whatsappResponse.data);
                // alert("success");
                this.$swal({
                    title: "Updated",
                    text: "Tracking Information successfully updated.",
                    icon: "success"
                });
                this.fetchOrder();
                // Handle success - e.g., show a success message or refresh the page
            } catch (error) {
                console.log('Error updating shipping info:', error);
                // Handle error
                this.$swal({
                    title: "Error",
                    text: "An error occured! Please try again",
                    icon: "Error"
                });
            }
        },
        async sendEmail() {
            // const response = await axios.post(this.ENDPOINT + `/send-email`, {
            //     orderId: this.order.id,
            //     trackingId: this.trackingId,
            //     first_name: this.order.shipping.first_name,
            //     email: this.order.shipping.email ? this.order.shipping.email : this.order.billing.email
            // });
            // console.log("response", response);

            var recipient = this.order.shipping.email ? this.order.shipping.email : this.order.billing.email;
            var subject = `Tracking details of Order Id: ${this.order.id}`;
            var body = `Dear ${this.order.shipping.first_name},

Thank you for choosing Calcutta Records Company! We are excited to inform you that your order has been shipped and is on its way to you. Here are the tracking details for your reference:

Tracking Link: https://track.calcuttarecords.com/${this.order.id}

Consignment Number: ${this.trackingId}

You can track your package's progress by visiting the tracking website and entering your tracking number. If you have any questions or need further assistance, please don't hesitate to reach out to our customer support team at calcuttarecordcompany@gmail.com.

We appreciate your business and look forward to delivering your order to you soon.

Best regards,
Calcutta Records Company Team
            `;

            // Encode the HTML content
            body = encodeURIComponent(body);

            // Construct the mailto link
            var mailtoLink = 'mailto:' + recipient + '?subject=' + subject + '&body=' + body;

            // Open the user's default email client
            window.open(mailtoLink, '_blank');
        },
        async sendEmailNM() {
            this.isDialogTwoOpen = true;
            const response = await axios.post(this.ENDPOINT + `/send-email?status=shipped`, {
                orderId: this.order.id,
                trackingId: this.trackingId,
                first_name: this.order.shipping.first_name,
                email: this.order.shipping.email ? this.order.shipping.email : this.order.billing.email
            });
            console.log("response", response.data);
            if (response.data.response == "250 Great success") {
                this.isDialogTwoOpen = false;
                this.$swal({
                    title: "Success!",
                    text: "Email sent successfully!",
                    icon: "success"
                });
            } else {
                this.isDialogTwoOpen = false;
                this.$swal({
                    title: "Error",
                    text: "An Error occured please try again",
                    icon: "success"
                });
            }

        },
        async sendWhatsapp() {
            var recipient = this.order.shipping.phone ? this.order.shipping.phone : this.order.billing.phone;
            const link = `https://api.whatsapp.com/send?phone=${recipient}`;
            window.open(link, '_blank');
        }
    },


};
</script>
<style scoped>
.warn-resolved {
    border: 1px solid orange;
    border-radius: 8px;
    padding: 0.2rem 0.5rem;
    margin-block: 1rem;

    p {
        color: orange;
        margin: 0;
    }
}

.confirm-text {
    font-size: 1.1rem;
    margin-block: 1rem;
}

.chip-red {
    color: white;
}

.order-status {
    display: flex;
    align-items: center;
}

.order-status p {
    margin: 0;
    margin-right: 0.5rem;
    font-size: 1.3rem;
    font-weight: 500;
}

.tracking-container {
    margin-top: 1rem;
    border: 1px solid black;
    border-radius: 10px;
    padding: 1rem;
}

.send-button {
    color: white;
}
</style>