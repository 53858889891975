<template>
    <v-container class="main-container">

        <v-text-field v-model="searchQuery" label="Search Orders" @input="fetchOrders"></v-text-field>

        <v-row class="ma-2" justify="end">
            <v-btn v-if="showingFR" class="mx-2" @click="exportToCSV">Export</v-btn>
            <v-btn v-if="!showingFR" @click="isFilterOn = true"><v-icon>mdi-filter</v-icon> Filter</v-btn>
            <v-btn v-else @click="removeFilter"><v-icon>mdi-close</v-icon> Remove Filter</v-btn>
        </v-row>

        <v-data-table :headers="headers" :items="orders" class="elevation-1" :options.sync="options"
            :server-items-length="totalOrders" :loading="loading" loading-text="Loading... Please wait" hide-default-footer>
            <!-- Data table slots for custom rendering -->
        </v-data-table>
        <div class="d-flex align-center justify-end my-3">
            <v-pagination v-model="options.page" :length="totalPages" :total-visible="5"></v-pagination>
        </div>
        <v-dialog v-model="isFilterOn" persisted class="pa-4 custom-dialog" width="auto" persistent>
            <v-card class="d-flex justify-center flex-column align-center">
                <v-card-title class="my-1">Choose filters</v-card-title>
                <!-- <v-radio-group v-model="radioGroupOption" row>
                    <v-radio v-for="item in radiovalue" :key="item" :label="item" :value="item"></v-radio>
                </v-radio-group> -->
                <div class="d-flex align-center justify-space-between flex-wrap custom-flex">
                    <div class="custom-date-width">
                        <p class="my-2 text-h6 text-center">Start Date</p>
                        <v-date-picker class="mx-4 text-center" v-model="startDate"></v-date-picker>
                    </div>
                    <div class="custom-date-width">
                        <p class="my-2 text-h6 text-center">End Date</p>
                        <v-date-picker class="mx-4 text-center" v-model="endDate"></v-date-picker>
                    </div>

                </div>
                <div class="text-center">
                    <!-- <v-radio-group v-model="status" row>
                        <v-radio v-for="item in statusOption" :key="item" :label="item" :value="item"></v-radio>
                    </v-radio-group> -->
                    <p class="my-2 text-h6">Choose Order Status</p>
                    <div class="d-flex align-center justify-space-between flex-wrap">

                        <v-checkbox class="px-2" v-for="item in statusOption" v-model="status" :label="item"
                            :value="item"></v-checkbox>
                    </div>
                </div>
                <!-- <div class="text-center"> -->
                <!-- <v-radio-group v-model="status" row>
                        <v-radio v-for="item in statusOption" :key="item" :label="item" :value="item"></v-radio>
                    </v-radio-group> -->
                <!-- <p class="my-2 text-h6">Choose Payment Method</p>
                    <div class="d-flex align-center justify-space-between flex-wrap">

                        <v-checkbox class="px-2" v-for="item in paymentMethod" v-model="payment" :label="item"
                            :value="item"></v-checkbox>
                    </div>
                </div> -->
                <v-card-actions class="mt-2" justify="end">
                    <v-btn color="primary" plain @click="submitFilter">Submit</v-btn>
                    <v-btn color="red" plain @click="closeDialog">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isDialogOpen" persistent max-width="450">
            <v-card class="d-flex align-center justify-center pa-7">

                <v-progress-circular :size="50" color="purple" indeterminate></v-progress-circular>
                <v-card-title class="text-h6">Processing...</v-card-title>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
import axios from 'axios';
import moment from "moment-timezone";
import Papa from 'papaparse';
export default {
    data() {
        return {
            ENDPOINT: process.env.VUE_APP_ENDPOINT,
            totalOrders: 0,
            options: {},
            searchQuery: '',
            orders: [], // Array to hold orders data
            headers: [
                { text: 'Order ID', value: 'id' },
                { text: 'Customer Name and Phone', value: 'customer_info' },
                { text: 'Customer Address', value: 'address' },
                { text: 'Customer State', value: 'state' },
                { text: 'Total Amount', value: 'total' },
                { text: 'GST Amount', value: 'gst' },
                { text: 'Shipped By', value: 'shipped_by' },
                { text: 'Tracking No', value: 'tracking_no' },
                { text: 'Shipping Cost', value: 'shipping_cost' },
                { text: 'Order Date', value: 'order_date' },
                { text: 'Shipping Date', value: 'shipping_date' },
                { text: 'Item Count', value: 'item_count' },
                { text: 'Status', value: 'status' },
                { text: 'Paid By', value: 'payment_method' },
                { text: 'Source Type', value: 'source_type' },
                { text: 'UTM Source', value: 'utm_source' }
            ],
            loading: true,
            showingFR: false,
            isFilterOn: false,
            isDialogOpen: false,
            startDate: '',
            endDate: '',
            status: [],
            radioGroupOption: '',
            statusOption: ['processing', 'ready', 'shipped', 'packed', 'completed', 'resolved'],
            radiovalue: ['date', 'status'],
            paymentMethod: ['phonepe', 'upi', 'cash on delivery'],
            payment: [],
            totalPages: 1
        };
    },
    // mounted() {
    //     this.fetchOrders();
    // },
    watch: {
        options: {
            handler() {
                this.submitFilter();
            },
            deep: true,
        },
    },
    methods: {
        async submitFilter() {
            console.log("submitfilter fired");
            console.log("loading: ", this.loading);
            if (this.startDate === '' && this.endDate === '' && this.status.length === 0 && this.payment.length === 0) {
                console.log("No Filter fired");
                this.loading = true;
                console.log("loading: ", this.loading);
                await this.fetchOrders();
                this.showingFR = false;
                this.isFilterOn = false;
                this.loading = false;
                console.log("loading: ", this.loading);

            } else if (this.startDate.length || this.endDate.length) {
                if (this.startDate === '' || this.endDate === '') {
                    this.$swal({
                        title: "Error",
                        text: "Start Date and End Date cannot be blank. Please select a valid date to continue",
                        icon: "error"
                    });
                    this.isFilterOn = false;
                    return;

                }
                this.loading = true;

                // this.isDialogOpen = true;
                console.log("Filter fired");
                const start = this.startDate != "" ? this.startDate : "blank";
                const end = this.endDate != "" ? this.endDate : "blank";
                const stat = this.status.length ? this.status : ["processing", "packed", "ready", "shipped", "completed"];
                const payment = this.payment.length ? this.payment : "any";
                const { page, itemsPerPage } = this.options;
                const url = this.ENDPOINT + `/reports?search=${this.searchQuery}&page=${page}&per_page=${itemsPerPage}&startDate=${start}&endDate=${end}&status=${stat}&payment=${payment}`;
                console.log("url :", url);

                try {
                    const response = await axios.get(url);
                    this.orders = this.processOrders(response.data.orders);

                    console.log(this.orders)

                    this.totalOrders = Number(response.data.totalOrders);
                    this.totalPages = Number(response.data.totalPages);
                    console.log("purhcase total orders: ", this.totalOrders); // Update this line
                    // this.totalPages = response.data.totalPages; // Add this line
                    this.showingFR = true;
                    this.isFilterOn = false;
                } catch (e) {
                    console.log(e);
                } finally {
                    this.loading = false;
                }
                // this.isDialogOpen = false

                // console.log("date: ", this.date); //date format YYYY-MM-DD
                //     this.loading = true;

                //     try {

                //         const { page, itemsPerPage } = this.options;


                //         const response = await axios.get(this.ENDPOINT + `/orders?search=${this.searchQuery}&page=${page}&per_page=${itemsPerPage}&startDate=${this.startDate}&endDate=${this.endDate}`);
                //         this.orders = this.processOrders(response.data.orders);

                //         console.log(this.orders)

                //         this.totalOrders = Number(response.data.totalOrders);
                //         console.log("purhcase total orders: ", this.totalOrders); // Update this line
                //         // this.totalPages = response.data.totalPages; // Add this line

                //     } catch (error) {
                //         console.error('Error fetching orders:', error);
                //     } finally {
                //         this.loading = false;
                //     }
                //     this.showingFR = true;
                //     this.isFilterOn = false;

                // } else if (this.radioGroupOption == 'status') {
                //     // console.log("status: ", this.status);
                //     this.loading = true;

                //     try {

                //         const { page, itemsPerPage } = this.options;


                //         const response = await axios.get(this.ENDPOINT + `/orders?search=${this.searchQuery}&page=${page}&per_page=${itemsPerPage}&status=${this.status}`);
                //         this.orders = this.processOrders(response.data.orders);

                //         console.log(this.orders)

                //         this.totalOrders = Number(response.data.totalOrders);
                //         console.log("purhcase total orders: ", this.totalOrders); // Update this line
                //         // this.totalPages = response.data.totalPages; // Add this line

                //     } catch (error) {
                //         console.error('Error fetching orders:', error);
                //     } finally {
                //         this.loading = false;
                //     }
                //     this.showingFR = true;
                //     this.isFilterOn = false;
            }

        },
        async removeFilter() {
            this.loading = true;
            this.showingFR = false;
            this.startDate = "";
            this.endDate = "";
            this.status = [];
            this.payment = [];
            await this.fetchOrders();
            this.loading = false;
        },
        async exportToCSV() {
            this.isDialogOpen = true;
            // this.options.page = 1;
            const start = this.startDate != "" ? this.startDate : "blank";
            const end = this.endDate != "" ? this.endDate : "blank";
            const stat = this.status.length ? this.status : ["processing", "packed", "ready", "shipped"];
            const payment = this.payment.length ? this.payment : "any";
            const { page, itemsPerPage } = this.options;
            const url = this.ENDPOINT + `/cus-reports?search=${this.searchQuery}&page=1&per_page=${this.totalOrders}&startDate=${start}&endDate=${end}&status=${stat}&payment=${payment}`;
            console.log("url :", url);

            try {
                const response = await axios.get(url);
                const customOrders = this.processOrders(response.data.orders);
                console.log("Custom Orders: ", customOrders);
                const headers = [
                    'Order ID',
                    'Customer Name and Phone',
                    'Customer Address',
                    'Customer State',
                    'Total Amount',
                    'GST Amount',
                    'Shipped By',
                    'Tracking No',
                    'Shipping Cost',
                    'Order Date',
                    'Shipping Date',
                    'Item Count',
                    'Status',
                    'Paid By',
                    'Source Type',
                    'UTM Source'
                ];


                const csvData = Papa.unparse({
                    fields: headers,
                    data: customOrders.map(order => { // Map each order to an array of cell values



                        return [
                            order.id, order.customer_info, order.address, order.state, order.total, order.gst, order.shipped_by, order.tracking_no, order.shipping_cost, order.order_date, moment(order.shipping_date).format('DD-MM-YYYY'), order.item_count, order.status, order.payment_method, order.source_type,
                            order.utm_source,
                        ];
                    }),
                });
                const filename = 'orders.csv';

                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = filename;
                anchor.click();
            } catch (e) {
                console.log(e);
            }
            this.isDialogOpen = false;
        },
        async fetchOrders() {

            try {

                const { page, itemsPerPage } = this.options;


                const response = await axios.get(this.ENDPOINT + `/orders?search=${this.searchQuery}&page=${page}&per_page=${itemsPerPage}`);
                console.log(response.data.orders)
                this.orders = this.processOrders(response.data.orders);



                this.totalOrders = Number(response.data.totalOrders); // Update this line
                this.totalPages = Number(response.data.totalPages);
                console.log("Total Orders: ", this.orders);
                // this.totalPages = response.data.totalPages; // Add this line

            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        },

        processOrders(orders) {
            return orders.map(order => ({
                id: order.id,
                customer_info: `${order.billing.first_name} ${order.billing.last_name}, ${order.billing.phone}`,
                address: `${order.billing.address_1}, ${order.billing.city}`,
                state: order.billing.state,
                total: order.total,
                gst: order.total_tax,
                shipped_by: order.meta_data.find(meta => meta.key === 'shipping_company')?.value || '',
                tracking_no: order.meta_data.find(meta => meta.key === 'tracking_id')?.value || '',
                shipping_cost: order.meta_data.find(meta => meta.key === 'shipping_cost')?.value || '',
                order_date: moment(order.date_created).format('DD-MM-YYYY'),
                shipping_date: order.meta_data.find(meta => meta.key === 'shipping_date')?.value || '',
                item_count: order.line_items.length,
                status: order.status,
                payment_method: order.payment_method_title ? order.payment_method_title : "",
                source_type: order.meta_data.find(meta => meta.key === '_wc_order_source_attribution_source_type')?.value || 'Not specified',
                utm_source: order.meta_data.find(meta => meta.key === '_wc_order_source_attribution_utm_source')?.value || 'Not specified'

                // Note: Adjust these keys based on your actual API response structure
            }));
        },
        closeDialog() {
            this.startDate = "";
            this.endDate = "";
            this.status = [];
            this.payment = [];
            this.isFilterOn = false;
        }

    },

};
</script>
<style scoped>
.main-container {
    max-width: 95vw;
}

@media only screen and (max-width: 500px) {
    .custom-date-width {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

}
</style>