import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VBtn,{on:{"click":function($event){return _vm.$router.replace('/home')}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1)],1)],1),_c(VRow,[_c(VCol,[_c('h1',[_vm._v("Choose Product Type")])])],1),_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":"","outlined":""}},[_c(VCardTitle,{staticClass:"text-center"},[_c('h2')]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"items":_vm.formats,"label":"Format"},model:{value:(_vm.format),callback:function ($$v) {_vm.format=$$v},expression:"format"}})],1)],1)],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'Release-Other', query: { mode: 'new', format: _vm.format } })}}},[_vm._v("Create")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }