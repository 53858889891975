<template>
  <v-container>

    <v-row>
      <v-col>

        <v-btn @click="$router.replace('/home')"><v-icon>mdi-arrow-left</v-icon></v-btn>

      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <v-card flat outlined class="pa-8 text-center">
          <v-icon size="50">mdi-album</v-icon>
          <
          <br />
          Vinyl Record
        </v-card>
      </v-col>
      <v-col>
        <v-card flat outlined class="pa-8 text-center ">
          <v-icon size="50">mdi-disc</v-icon>
          <br />
          Music CD
        </v-card>
      </v-col>
      <v-col>
        <v-card flat outlined class="pa-8 text-center ">
          <v-icon size="50">mdi-cassette</v-icon>
          <br />
          Cassette
        </v-card>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <h1>Enter Catlog Number</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mx-auto" flat outlined>
          <v-card-title class="text-center">
            <h2></h2>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-select :items="formats" v-model="format" label="Format"></v-select>
              </v-col>
              <v-col cols="8">

                <v-text-field v-model="query" label="Catlog or Name" outlined></v-text-field>

              </v-col>
            </v-row>

            - </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col> <v-btn @click="search()" color="success">Find Item</v-btn></v-col>
      <v-col> <v-btn @click="$router.push({ name: 'Release', query: { mode: 'new' } })" color="success">Create Without
          Catlog</v-btn></v-col>

    </v-row>

    <v-row>

      <v-col cols="12">
        <v-data-table @click:row="goToRelease" :headers="headers" :items="releases" :loading="loading"
          :items-per-page="10">

          <template v-slot:item.cover_image="{ item }">
            <v-img :src="item.cover_image" :alt="item.title" width="100"></v-img>
          </template>

        </v-data-table>
      </v-col>
    </v-row>




    <!-- <hello-world /> -->

  </v-container>
</template>

<script>
import HelloWorld from '../components/HelloWorld'

export default {
  name: 'Home',

  components: {
    HelloWorld,
  },

  data() {
    return {
      searchQuery: '',
      query: "",
      releases: [],
      format: "vinyl",
      formats: [
        "vinyl",
        "cd",
        "cassette",
      ],
      loading: false,
      headers: [
        { text: 'Cover Image', value: 'cover_image' },

        // { text: 'Artist', value: 'artist' },
        { text: 'Title', value: 'title' },
        { text: 'Year', value: 'year' },
        { text: 'Type', value: 'type' },
        { text: 'Format', value: 'format' },
        { text: 'Label', value: 'label' },
        { text: 'Cat No', value: 'catno' },
      ]
    };
  },
  methods: {
    goToRelease(item) {
      this.$router.push({ name: 'Release', params: { id: item.id, mode: 'new' } })
    },
    async search() {




      try {
        this.loading = true;
        this.releases = []
        const response = await fetch(`https://api.discogs.com/database/search?q=${this.query}&format=${this.format}&&type=release&token=usdPFhZrOaahtQhbYCQvJXXHDtZQrrVGUDYFCnzV`);
        const data = await response.json();
        this.releases = data.results.map((release) => ({
          id: release.id,
          cover_image: release.cover_image,
          title: release.title,
          // artist: release.artist,
          type: release.format.join(","),
          resource_url: release.resource_url,
          year: release.year,
          country: release.country,
          format: release.format,
          label: release.label[0],
          catno: release.catno,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }




    }
  }
}
</script>
<style>
.choose-card {
  border: 1px solid;
  margin: 5px;

  padding: 40px;
  font-size: xx-large;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
}
</style>