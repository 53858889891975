<template>
  <v-app>
    <v-app-bar v-if="$route.name != 'Login'" app light>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-img alt="Calcutta" class="shrink mr-2" contain
          src="https://calcuttarecords.com/wp-content/uploads/2023/05/cropped-cropped-cropped-cropped-CalcuttaRecordCompany-2-01-150x150-1-1.webp"
          transition="scale-transition" width="40" />
        CalcuttaRecords Manager V1.6

      </div>

      <v-spacer></v-spacer>
      <v-btn @click="refresh">Reload</v-btn>

    </v-app-bar>

    <v-navigation-drawer v-if="$route.name != 'Login'" app v-model="drawer">
      <!-- ... your existing menu items ... -->
      <v-list-item v-if="user.role == 'manager' || user.role == 'agent'" link to="/home">
        <v-list-item-icon>
          <v-icon>mdi-archive</v-icon> <!-- Example icon -->
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inventory</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- For shipping vendor only -->
      <v-list-item v-if="user.role == 'shipping'" link to="/dashboard">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- New Menu Item for Packaging -->
      <v-list-item v-if="user.role == 'manager'" link to="/purchase">
        <v-list-item-icon>
          <v-icon>mdi-package-variant-closed</v-icon> <!-- Example icon -->
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Purchase</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.role == 'manager' || user.role == 'agent'" link to="/packaging">
        <v-list-item-icon>
          <v-icon>mdi-package-variant-closed</v-icon> <!-- Example icon -->
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Packaging</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.role == 'manager'" link to="/shipping">
        <v-list-item-icon>
          <v-icon>mdi-truck</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Shipping</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.role == 'manager'" link to="/reports">
        <v-list-item-icon>
          <v-icon>mdi-file-document</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Reports</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'App',

  data: () => ({
    //
    drawer: false,

  }),
  computed: {
    ...mapState(['user']),
  },
  methods: {
    refresh() {
      window.location.reload()
    },

    async logout() {
      await this.$store.dispatch('logout');
      this.$router.go();
    }

  }
};
</script>
