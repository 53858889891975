<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <div class="d-flex align-center">
                    <v-btn @click="$router.replace('/home')"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <h3 class="mx-4">{{ release.title }}</h3>
                </div>
            </v-col>

        </v-row>
        <!-- <v-row>
            <v-col cols="12">
                <v-card flat outlined>
                    <v-card-title>Tracklist</v-card-title>
                    <v-card-text> -->
        <!-- <p><strong>Tracklist:</strong></p>
                        <ol>
                            <v-text-field :rules="[v => !!v || 'Field is required']" v-for="(track, index) in release.tracklist" :key="index" v-model="release.tracklist[index]">{{ track.title }}</v-text-field>
                        </ol> -->
        <!-- <v-data-table :headers="headers" :items="release.tracklist" :items-per-page="15"
                            class="elevation-1">
                            <template v-slot:item.title="{ item }">
                                <v-edit-dialog :return-value.sync="item.title" large persistent>
                                    <div>{{ item.title }}</div>
                                    <template v-slot:input>
                                        <div class="mt-4 title">Edit Title</div>
                                        <v-text-field v-model="item.title" single-line autofocus></v-text-field>
                                    </template>
</v-edit-dialog>
</template>

<template v-slot:item.position="{ item }">
                                <v-edit-dialog :return-value.sync="item.position" large persistent>
                                    <div>{{ item.position }}</div>
                                    <template v-slot:input>
                                        <div class="mt-4 title">Edit Position</div>
                                        <v-text-field v-model="item.position" single-line autofocus></v-text-field>
                                    </template>
</v-edit-dialog>
</template>

<template v-slot:item.duration="{ item }">
                                <v-edit-dialog :return-value.sync="item.duration" large persistent>
                                    <div>{{ item.duration }}</div>
                                    <template v-slot:input>
                                        <div class="mt-4 title">Edit Duration</div>
                                        <v-text-field v-model="item.duration" single-line autofocus></v-text-field>
                                    </template>
</v-edit-dialog>
</template>

<template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="removeTrack(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
</v-data-table>
<v-btn @click="addTrack">Add Track</v-btn>


</v-card-text>
</v-card>
</v-col>

</v-row> -->
        <!-- ? Condition Info and Product Information -->
        <v-row>
            <v-col>
                <v-card class="mx-auto">
                    <v-card-title class="text-center">Product Info</v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="6" md="6">
                                <v-text-field v-model="release.title" label="Title"></v-text-field>
                            </v-col>
                            <v-col v-if="format == 'Audio Equipment' || format == 'Watch'" cols="6" md="6">
                                <v-select v-model="release.itemCondition" :items="conditionOptions" item-title="text"
                                    item-value="value" :label="`${format} Condition`" required />
                            </v-col>
                            <v-col v-if="format != 'Audio Equipment' && format != 'Watch'" cols="6" md="6">
                                <v-select v-model="release.coverCondition" :items="conditionOptions" item-title="text"
                                    item-value="value" label="Cover Condition" required />
                            </v-col>
                            <v-col v-if="format != 'Audio Equipment' && format != 'Watch'" cols="6" md="6">
                                <v-select v-model="release.recordCondition" :items="conditionOptions" item-title="text"
                                    item-value="value" label="Disc Condition" required />
                            </v-col>
                            <v-col v-if="format == 'Audio Equipment' || format == 'Watch'" cols="6" md="6">
                                <v-text-field v-model="release.model" label="Model"></v-text-field>
                            </v-col>
                            <v-col v-if="format == 'Audio Equipment' || format == 'Watch'" cols="6" md="6">
                                <v-text-field v-model="release.manufacturer" label="Manufacturer"></v-text-field>
                            </v-col>
                            <v-col v-if="format != 'Watch' && format != 'Audio Equipment'" cols="6" md="6">
                                <v-select v-model="release.language"
                                    :items="['English', 'Hindi', 'Bengali', 'Gujrati', 'Tamil', 'Telegu', 'Malayalam', 'Punjabi', 'Spanish', 'Italian', 'French', 'Russian', 'Japanese']"
                                    label="Language" required />
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-select v-model="release.country" :items="countries" label="Country" required />
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field v-model="release.year" label="Year" required></v-text-field>
                            </v-col>
                            <v-col v-if="format != 'Audio Equipment'" cols="6" md="6">
                                <v-text-field v-model="release.region" label="Region" required></v-text-field>
                            </v-col>
                            <v-col v-if="format == 'Games'" cols="6" md="6">
                                <v-select v-model="release.console"
                                    :items="['ps1', 'ps2', 'ps3', 'ps4', 'ps5', 'xbox', 'xbox360', 'xbox series x', 'xbox series s']"
                                    label="Console" required />
                            </v-col>
                            <v-col v-if="format == 'Watch'" cols="6" md="6">
                                <v-select v-model="release.mechanism"
                                    :items="['Automatic Manual', 'Manual', 'Quartz', 'Battery', 'Other']"
                                    label="Mechanism" required />
                            </v-col>
                            <!-- <v-col cols="6" md="6">
                                <v-select v-model="release.speed" :items="['33', '45', '78']" label="Speed" required />
                            </v-col> -->

                            <!-- <v-col cols="6" md="6">
                                <v-select v-model="release.format"
                                    :items="['LP', 'EP', 'SP', 'CD', 'SACD', 'SHMCD', 'DVDAUDIO', 'CASSETTE']"
                                    label="Format" required />
                            </v-col> -->


                            <v-col cols="6" md="6">
                                <v-select :items="['New', 'Preowned']" v-model="release.usageCondition"
                                    label="Usage Condition" @input="updateCategory"></v-select>

                            </v-col>
                            <v-col cols="6" md="6">
                                <v-select v-model="release.wcCategory" :items="categoryOptions" label="Category"
                                    disabled />
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field :rules="[v => !!v || 'Field is required']" v-model="release.listPrice"
                                    label="List Price"></v-text-field>

                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field :rules="[v => !!v || 'Field is required']" v-model="release.sellingPrice"
                                    label="Selling Price"></v-text-field>

                            </v-col>
                            <v-col cols="6" md="6">
                                <v-select v-model="purchase" :items="purchases" item-text="purchaseId" item-value="_id"
                                    label="Purchase Id" return-object @change="handlePurchaseChange" />
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field :rules="[v => !!v || 'Field is required']" v-model="release.buyingPrice"
                                    label="Buying Price"></v-text-field>

                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field :rules="[v => !!v || 'Field is required']" v-model="release.noofrec"
                                    label="No Of Disc"></v-text-field>

                            </v-col>

                            <v-col cols="6" md="6">
                                <v-text-field :rules="[v => !!v || 'Field is required']"
                                    v-model="release.inventoryLocation" label="Inventory Location"></v-text-field>

                            </v-col>

                            <v-col class="d-flex flex-wrap align-center justify-space-around" cols="6" md="6">
                                <v-checkbox v-model="release.customCategory" label="Featured"
                                    value="Featured"></v-checkbox>
                                <v-checkbox v-model="release.customCategory" label="Import" value="Import"></v-checkbox>
                                <v-checkbox v-model="release.customCategory" label="Japanese Pressings"
                                    value="Japanese Pressings"></v-checkbox>
                            </v-col>

                        </v-row>
                        <!-- 
                        <v-file-input v-model="release.frontImage" label="Front Image"></v-file-input>
                        <v-file-input v-model="release.backImage" label="Back Image"></v-file-input>
                        <v-file-input v-model="release.gateImage" label="Gate Fold Image"></v-file-input> -->
                    </v-card-text>


                    <!-- <v-img :src="release.images[0].uri" aspect-ratio="1"></v-img> -->
                </v-card>

            </v-col>

        </v-row>
        <v-row>

            <v-col>

                <v-card class="mx-auto">
                    <v-card-title class="text-center">Images</v-card-title>
                    <v-card-text>

                        <v-file-input label="Upload or take a picture" accept="image/*" @change="onFileChange"
                            :disabled="mode == 'edit'"></v-file-input>

                    </v-card-text>
                </v-card>


                <!-- image list -->
                <v-row v-if="mode == 'new'">
                    <v-col v-for="(  img, i  ) in images  " :key="i" cols="12" sm="4">

                        <v-img :src="img"></v-img>
                        <v-btn small @click="removeImage(i)">Remove</v-btn>
                    </v-col>

                </v-row>
                <v-row v-if="mode == 'edit'">
                    <v-col>

                        <div v-for="(  image, index  ) in release.images  " :key="index" class="image-wrapper">
                            <img :src="`${ENDPOINT}/uploads/compressed/${image}`" alt="Uploaded" width="100"
                                height="100">

                        </div>
                    </v-col>
                </v-row>

            </v-col>



        </v-row>

        <!-- save button -->



        <v-row>

            <v-col>

                <!-- 
                <finalrelease :release="responseData"></finalrelease>

 -->


                <v-card>
                    <v-card-title>Descriptions</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field :rules="[v => !!v || 'Field is required']" label="Name"
                                        v-model="release.title"></v-text-field>
                                </v-col>

                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-textarea label="Description" v-model="release.description"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field :rules="[v => !!v || 'Field is required']" label="Short Description"
                                        v-model="release.short_description"></v-text-field>
                                </v-col>
                            </v-row>
                            <!-- Keywords part -->
                            <v-row>
                                <v-col cols="12">
                                    <v-combobox v-model="release.keywords" :items="release.keywords" hide-selected
                                        label="Keywords" multiple small-chips>
                                        <template v-slot:no-data>
                                            <v-list-item @click="addKeyword(search)">

                                            </v-list-item>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <v-chip v-bind="attrs" :color="`${item.color || 'primary'} lighten-3`"
                                                :input-value="selected" label small>
                                                <span class="pr-2">{{ item }}</span>
                                                <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-chip :color="`${item.color || 'primary'} lighten-3`" dark label small>
                                                {{ item }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field :rules="[v => !!v || 'Field is required']" label="Weight"
                                        v-model="release.weight"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field :rules="[v => !!v || 'Field is required']" label="Length"
                                                v-model="release.dimensions.length"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field :rules="[v => !!v || 'Field is required']" label="Width"
                                                v-model="release.dimensions.width"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field :rules="[v => !!v || 'Field is required']" label="Height"
                                                v-model="release.dimensions.height"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6" v-for="(  attribute, index  ) in release.attributes  "
                                    :key="index">
                                    <v-select :label="attribute.name" v-model="attribute.options" multiple
                                        :items="attribute.options"></v-select>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-card-text>

                    <v-card-actions v-if="mode == 'edit'">
                        <v-btn @click="deleteListing">Delete</v-btn>
                        <v-btn @click="updateListing">Update</v-btn>
                        <v-btn v-if="release.published == false" color="primary" @click="publish">Publish</v-btn>
                    </v-card-actions>
                    <v-card-actions v-else>
                        <v-btn @click="generateGPT">Write Desc</v-btn>
                        <v-btn color="primary" @click="save">Create SKU</v-btn>
                    </v-card-actions>
                    <v-overlay :value="overlay">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                </v-card>

            </v-col>
        </v-row>


        <v-dialog v-model="cropDialog" max-width="450px" style="background:white">
            <v-card class="pa-3">
                <v-card-text>


                    <vue-cropper ref="cropper" v-if="cropDialog" :src="imageDataUrl" :guides="true"></vue-cropper>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cropDialog = false">Cancel</v-btn>

                    <v-btn @click="rotateImage">Rotate</v-btn>

                    <v-btn @click="cropImage">Crop</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import axios from 'axios';
import { countries } from '@/country';
// import finalrelease from "../components/FinalRelease.vue"
export default {
    components: {
        // finalrelease,
        VueCropper
    },
    computed: {
        mode() {
            return this.$route.params.mode
        },
        id() {
            return this.$route.params.id
        },
        format() {
            console.log("format: ", this.$route.query.format);
            return this.$route.query.format
        },
    },
    data() {
        return {

            ENDPOINT: process.env.VUE_APP_ENDPOINT,
            image: null,
            imageDataUrl: null,
            cropDialog: false,
            images: [],
            headers: [
                { text: 'Position', value: 'position', sortable: false },
                { text: 'Title', value: 'title', sortable: false },
                { text: 'Duration', value: 'duration', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false }
            ],
            overlay: false,
            release: {
                title: '',
                description: '',
                format: '',
                noofrec: 1,
                short_description: '',
                year: '',
                weight: '500',
                dimensions: {
                    length: 35,
                    width: 35,
                    height: 5
                },
                listPrice: 1499,
                speed: '33',
                sellingPrice: 1499,
                album: '',
                artist: '',
                genre: '',
                tracklist: [],
                language: 'English',
                label: '',
                catlogNo: '',
                recordCondition: '',
                coverCondition: '',
                itemCondition: '',
                usageCondition: 'Preowned',
                images: [],
                wcCategory: "",
                buyingPrice: 500,
                inventoryLocation: "A",
                country: "",
                region: "",
                year: "",
                console: "",
                model: "",
                manufacturer: "",
                mechanism: "",
                productType: "",
                customCategory: [],
                keywords: []
            },
            responseData: false,
            conOptions: ["New", "Preowned"],
            conditionOptions: [
                {
                    text: "Mint (M)",
                    value: "M"
                },
                {
                    text: "Near Mint (NM)",
                    value: "NM"
                },
                {
                    text: "Very Good Plus (VG+)",
                    value: "VG+"
                },
                {
                    text: "Very Good (VG)",
                    value: "VG"
                },
                {
                    text: "Very Good Minus (VG-)",
                    value: "VG-"
                },
                {
                    text: "Poor (P)",
                    value: "P"
                },
                {
                    text: "BSTOCK",
                    value: "BSTOCK"
                },
                {
                    text: "Remade",
                    value: "Remade"
                }
            ],
            categoryOptions: ["Preowned Watch", "Equipment", "Bluray", "DVD", "VCD", "Games"],
            categoryDisc: ["Audio", "Video"],
            countries: countries,
            search: '',
            colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
            nonce: 1,
            purchase: {},
            purchases: []
        }
    },
    async created() {


        const mode = this.$route.query.mode ? this.$route.query.mode : this.$route.params.mode

        console.log("Mode  ", this.$route)


        if (mode == "new") {
            const id = this.id
            // const response = await fetch(`https://api.discogs.com/releases/${id}?token=usdPFhZrOaahtQhbYCQvJXXHDtZQrrVGUDYFCnzV`)
            // var data = await response.json()
            // this.release.discogsuri = data.images[0].uri
            // this.release.lowest_price = data.lowest_price
            // this.release.want = data.community.want
            // this.release.have = data.community.have
            // this.release.artist = data.artists[0].name
            // this.release.country = data.country
            // this.release.genre = data.genres[0]
            // this.release.label = data.labels[0].name
            // this.release.catlogNo = data.labels[0].catno
            // this.release.year = data.year

            // this.release.title = data.title
            // this.release.album = data.title
            // this.release.tracklist = data.tracklist.map(e => {
            //     return {
            //         position: e.position,
            //         title: e.title,
            //         duration: e.duration,
            //     }
            // })
            // this.release.productType = this.format;

            switch (this.$route.query.format.toLowerCase()) {
                case 'bluray':
                    // Calculation for case 1
                    this.release.weight = '250';
                    break;

                case 'dvd':
                    // Calculation for case 2
                    this.release.weight = '250';
                    break;

                case 'vcd':
                    // Calculation for case 3
                    this.release.weight = '250';
                    break;

                default:
                    break;
            }

        } else if (mode == "edit") {
            console.log("Entered edit part of release other")
            const id = this.id
            console.log("Edit")
            const response = await axios.get(this.ENDPOINT + "/listingsbysku/" + id);
            this.release = response.data;
            console.log("release: ", this.release);
        }

        const resp = await axios.get(`${this.ENDPOINT}/purchases/ids`);

        console.log("purchase data: ", resp.data.purchases);
        this.purchases = resp.data.purchases

    },
    mounted() {
        // To assign the product category we need to fire this
        const category = `${this.release.usageCondition} ${this.format}`;
        const selectedItems = [];
        this.categoryOptions.forEach(item => {
            if (category.includes(item)) {
                selectedItems.push(item);
            }
        });
        this.release.wcCategory = selectedItems[0];
    },
    methods: {
        async handlePurchaseChange() {
            console.log("Selected Purchase ID:", this.purchase._id);
            // You can now use the selected ID in your logic
            const purchaseData = await axios.get(`${this.ENDPOINT}/purchases/${this.purchase._id}`);
            console.log("purchaseData.data : ", purchaseData.data)
            if (purchaseData.data._id) {
                this.release.buyingPrice = Math.round(purchaseData.data.amount / purchaseData.data.totalItems);
            }
        },
        addKeyword() {
            if (this.search && !this.release.keywords.includes(this.search)) {
                this.release.keywords.push(this.search);
                this.model.push(this.search);
                this.search = '';
            }
        },
        customFilter(item, queryText) {
            const text = item.toLowerCase();
            const query = queryText.toLowerCase();
            return text.indexOf(query) > -1;
        },
        updateCategory() {
            console.log("category code fired");
            const category = `${this.release.usageCondition} ${this.format}`;
            console.log("category: ", category);
            const selectedItems = [];
            console.log("category options: ", this.categoryOptions);
            this.categoryOptions.forEach(item => {
                if (category.includes(item)) {
                    selectedItems.push(item);
                }
            });
            this.release.wcCategory = selectedItems[0];
            console.log("selectedItems:", selectedItems);
            console.log("woocommerce category", this.release.wcCategory);
        },

        async publish() {
            var c = window.confirm("Have you updated the info? Are you sure you want to submit")
            this.overlay = true
            if (c) {
                try {
                    const data = {
                        customCategory: this.release.customCategory,
                    }
                    await axios.put(this.ENDPOINT + `/listings/${this.release._id}`, data);
                    await axios.post(this.ENDPOINT + "/listings/" + this.release._id + "/post")
                    this.overlay = false
                    alert("Published");
                    this.$router.push("/home");
                } catch (e) {
                    this.overlay = false;
                    console.log(e);
                    alert("Error" + e.data.error)
                }
            }

        },

        async updateListing() {


            this.overlay = true

            try {
                const response = await axios.put(this.ENDPOINT + `/listings/${this.release._id}`, this.release);
                this.release = response.data;


                alert('Listing updated successfully');
                this.overlay = false
                const currentPath = this.$router.currentRoute.fullPath;

                // this.$router.push({ path: `/dummy${Date.now()}` }).then(() => {
                //     this.$router.replace(currentPath);
                // });
                // this.$router.go()
                window.location = currentPath
            } catch (error) {
                this.overlay = false
                alert('Failed to update listing' + error.data.error);
            }
        },
        async deleteListing() {
            this.overlay = true
            try {
                await axios.delete(this.ENDPOINT + `/listings/${this.release._id}`);
                alert('Listing deleted successfully');
                this.overlay = false
                this.$router.replace('/home') // Redirect to home after delete
            } catch (error) {
                console.log(error)
                this.overlay = false
                alert('Failed to delete listing');
            }
        },

        removeImageForRelease(i) {
            this.release.images.splice(i, 1);

        },

        async save() {
            this.overlay = true
            this.release.productType = this.$route.query.format;
            this.release.format = this.$route.query.format;
            const self = this;

            console.log("release", this.release);


            const imageBlobs = await Promise.all(
                this.images.map(async (img, i) => {
                    const blob = await self.dataURLtoBlob(img);
                    function cleanString(inputStr) {
                        return inputStr.replace(/[^a-zA-Z0-9]/g, '');
                    }

                    let timestamp = new Date().getTime(); // gets the current time in milliseconds
                    let cleanedTitle = cleanString(self.release.title);
                    let name = `${cleanedTitle}_image${i}_${timestamp}.png`;

                    return { blob, name: name };

                })
            );

            const formData = new FormData();
            imageBlobs.forEach(({ blob, name }) => {
                formData.append('images', blob, name);
            });

            // Stringify release object and append it to formData
            formData.append('release', JSON.stringify(this.release));

            try {
                const res = await axios.post(this.ENDPOINT + '/savelisting', formData);

                // handle server response...
                this.sku = res.data.sku;  // assuming res.data.sku is the new SKU
                this.overlay = false

                alert("SKU Generated " + res.data.sku)
                this.$router.replace("/home");
            } catch (e) {
                this.overlay = false
                console.log(e)
                alert(e.data.error)
            }

            this.overlay = false



        },
        // ... your existing methods ...
        onFileChange(e) {
            console.log(e)
            const self = this
            this.imageDataUrl = '';
            const reader = new FileReader();
            reader.onload = e => {
                console.log(e)
                self.imageDataUrl = e.target.result;
                self.cropDialog = true;
            };
            reader.readAsDataURL(e);
        },

        rotateImage() {
            this.$refs.cropper.rotate(90);
        },
        cropImage() {
            this.images.push(this.$refs.cropper.getCroppedCanvas().toDataURL());
            this.imageDataUrl = '';

            this.cropDialog = false;
        },
        removeImage(index) {
            this.images.splice(index, 1);
        },

        dataURLtoBlob(dataurl) {
            let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        addTrack() {
            this.release.tracklist.push({
                position: '',
                title: '',
                duration: ''
            });
        },
        removeTrack(item) {
            const index = this.release.tracklist.indexOf(item);
            if (index !== -1) {
                this.release.tracklist.splice(index, 1);
            }
        },
        async generateGPT() {
            this.overlay = true
            this.release.productType = this.$route.query.format;
            this.release.format = this.$route.query.format;

            console.log("Generate ", JSON.stringify(this.release))

            this.responseData = false
            var genre = this.release.genre

            // const data = {
            //     title: this.release.title,
            //     artist: this.release.artist,
            //     year: this.release.year,
            //     label: this.release.label,
            //     genre: genre,
            //     coverCondition: this.release.coverCondition,
            //     recordCondition: this.release.recordCondition,
            //     format: this.release.format,
            //     language: this.release.language,
            //     speed: this.release.speed,
            //     country: this.release.country,
            //     noofrec: this.release.noofrec,
            //     condition: this.release.condition

            // }

            try {

                const response = await axios.post(`${this.ENDPOINT}/generatedesc`, this.release);

                this.release.title = this.release.productType == "Games" ? `${response.data.name} ${this.release.console} game` : (this.release.customCategory.includes("Japanese Pressings") ? `${response.data.name} Japanese Pressing` : response.data.name);
                // console.log("Title: ", this.release.title);
                this.release.description = response.data.description
                this.release.short_description = response.data.short_description
                this.release.keywords = response.data.keywords
                this.overlay = false
            } catch (e) {
                alert("Error")
                this.overlay = false
            }
            //Minify the images using tinify

            //Prepare the info for wordpress

            //Submit to wordpress

        }
    }
}
</script>