import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"custom-container",attrs:{"fluid":""}},[_c(VRow,{staticClass:"d-flex align-center justify-center card-container"},[_c(VCard,{staticClass:"pa-5",attrs:{"width":"40rem","max-width":"90vw"}},[_c('div',{staticClass:"top-header"},[_c(VIcon,{staticClass:"custom-icon",attrs:{"color":"white"}},[_vm._v("mdi-account")]),_c(VCardTitle,{staticClass:"text-center text-h3 my-4"},[_vm._v("Login")])],1),_c(VTextField,{attrs:{"label":"User Id","outlined":""},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userId"}}),_c(VTextField,{attrs:{"label":"Password","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"outlined":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}}),_c(VBtn,{staticClass:"py-5",attrs:{"color":"indigo white--text","block":""},on:{"click":_vm.handleLogin}},[_vm._v("Login")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }