import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{staticClass:"custom-dialog",attrs:{"width":"90vw","max-width":"800","persistent":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex align-center justify-space-between mb-4"},[_c('div',[_vm._v(" Order Items ")]),_c('div',{staticClass:"d-flex align-center"},[(_vm.gift)?_c('div',[_c(VChip,{attrs:{"variant":"flat","color":"primary"}},[_vm._v(" Gift Wrap ")])],1):_vm._e(),(_vm.expressDelivery)?_c('div',{staticClass:"mx-2"},[_c(VChip,{attrs:{"variant":"flat","color":"secondary"}},[_vm._v(" Express Delivery ")])],1):_vm._e()])]),_c(VCardText,[_vm._l((_vm.order.line_items),function(item){return _c(VRow,{key:item.id},[_c(VCol,[_c('img',{staticClass:"item-img",attrs:{"src":item.image.src,"alt":"item image"}})]),_c(VCol,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h6 custom-text"},[_vm._v(_vm._s(item.name))]),_c(VListItemSubtitle,{staticClass:"font-weight-black text-h6"},[_vm._v("SKU: "+_vm._s(item.sku))]),_c(VListItemSubtitle,{staticClass:"font-weight-black text-h6"},[_vm._v("Quantity: "+_vm._s(item.quantity))])],1)],1)],1)}),(_vm.giftMessage)?_c(VRow,[_c(VCol,[_c(VAlert,{attrs:{"border":"left","colored-border":"","color":"deep-purple accent-4","elevation":"2"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" Message ")]),_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.customMessage)+" ")])])],1)],1):_vm._e()],2),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.order.status == 'processing' ? 'Cancel' : 'Close'))]),(_vm.order.status == 'processing')?_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.confirmPacking}},[_vm._v("Confirm Packing")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }