import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage, // Use local storage for persistence
  reducer: (state) => {
    // Include all state fields that you want to persist
    return {
      user: state.user,
      // ... other state fields
    };
  },
});

const store = new Vuex.Store({
  state: {
    user: null,
    // ... other state fields
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    logout(state) {
      console.log("logout fired step: 1");
      state.user = null;
      localStorage.removeItem('vuex');
      // vuexLocal.remove('myapp-user'); // Remove user data with specified key
    },
    restore(state) {
      state.user = JSON.parse(localStorage.getItem("vuex"));
    }
  },
  actions: {
    loginUser({ commit }, user) {
      // ... Your authentication logic here
      commit("setUser", user);
    },
    logout({ commit }) {
      // ... Your logout logic here
      commit('logout'); // Reset user state and remove local storage data
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  plugins: [vuexLocal.plugin],
});

// Utilize the restored property for automatic state restoration
// store.state.restored.then(() => {
//   console.log("State restored!");
//   console.log(store.state);
  
//   // Perform any additional actions based on the restored state
// });

export default store;
