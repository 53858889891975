<template>
    <div>
        <v-container class="main-container">
            <div>
                <div class="d-flex align-center flex-wrap">
                    <v-text-field v-model="searchQuery" label="Enter Order Id"></v-text-field>
                    <v-btn class="mx-2" @click="searchFunc">Search</v-btn>
                    <v-btn class="mx-2" @click="clearSearch">Clear</v-btn>
                </div>

                <v-row class="ma-2" justify="end">
                    <v-btn v-if="!showingFR" @click="isFilterOn = true"><v-icon>mdi-filter</v-icon> Filter</v-btn>
                    <v-btn v-else @click="removeFilter"><v-icon>mdi-close</v-icon> Remove Filter</v-btn>
                </v-row>
                <v-data-table :headers="headers" :items="orders" class="elevation-1" :options.sync="options"
                    :server-items-length="totalOrders" :loading="loading" hide-default-footer>
                    <template v-slot:item.status="{ item }">
                        <v-chip :color="statusColor(item.status)" dark>{{ item.status }}</v-chip>
                    </template>
                    <template v-slot:item.billing="{ item }">
                        {{ formatCustomerName(item.billing) }}
                    </template>
                    <template v-slot:item.line_items="{ item }">
                        {{ formatLineItems(item.line_items) }}
                    </template>
                    <template v-slot:item.date_created="{ item }">
                        {{ formatDate(item.date_created) }}
                    </template>
                    <template v-slot:item.count="{ item }">
                        {{ item.line_items.length }}
                    </template>

                    <template v-slot:item.action="{ item }">
                        <!-- Action buttons here -->
                        <div class="custom-actions">
                            <v-btn class="ma-1" v-if="item.status != 'processing'" small color="#FFC107"
                                @click="openPackModal(item)">View</v-btn>
                            <v-btn class="ma-1" v-if="item.status == 'processing'" small color="primary"
                                @click="openPackModal(item)">Pack</v-btn>
                            <v-btn class="ma-1" small color="secondary" @click="printLabel(item)">Label</v-btn>
                            <v-btn class="ma-1" small color="secondary" @click="printLabelTwo">Logo</v-btn>
                            <v-btn class="ma-1" v-if="item.status == 'packed'" small color="success"
                                @click="handleStatus(item, 'ready')">Dispatch</v-btn>
                            <v-btn class="ma-1" v-if="item.status == 'shipped'" small color="green"
                                @click="handleStatus(item, 'completed')">Delivered</v-btn>
                            <v-btn class="ma-1" small color="primary"
                                @click="handleStatus(item, 'processing')">Reset</v-btn>
                        </div>

                    </template>
                </v-data-table>
                <div class="d-flex align-center justify-end my-3">
                    <v-pagination v-model="options.page" :length="totalPages" :options.sync="options"
                        :total-visible="5"></v-pagination>
                </div>

            </div>
            <v-dialog v-model="isDialogOpen" persistent max-width="450">
                <v-card class="d-flex align-center justify-center pa-7">

                    <v-progress-circular :size="50" color="purple" indeterminate></v-progress-circular>
                    <v-card-title class="text-h6">Updating and Sending Email...</v-card-title>
                </v-card>
            </v-dialog>
            <v-dialog v-model="isDialogTwoOpen" persistent max-width="450">
                <v-card class="d-flex align-center justify-center pa-7">

                    <v-progress-circular :size="50" color="purple" indeterminate></v-progress-circular>
                    <v-card-title class="text-h6">Updating...</v-card-title>
                </v-card>
            </v-dialog>
            <!-- Pack Modal Popup -->
            <pack-modal ref="packModal" :gift="gift" :expressDelivery="expressDelivery" :giftMessage="giftMessage"
                :customMessage="customMessage" :order="modalOrder" @confirmPacking="handleConfirmPacking"
                @resetFlags="resetFlags" :close="closeModal" />
            <!-- Modal to show filtering options -->
            <v-dialog v-model="isFilterOn" persisted class="pa-4 custom-dialog" width="auto">
                <v-card class="d-flex justify-center flex-column align-center">
                    <v-card-title class="my-1">Choose one filter</v-card-title>
                    <v-radio-group v-model="radioGroupOption" row>
                        <v-radio v-for="item in radiovalue" :key="item" :label="item" :value="item"></v-radio>
                    </v-radio-group>
                    <div v-if="radioGroupOption == 'date'">
                        <v-date-picker class="mx-4" v-model="date"></v-date-picker>
                    </div>
                    <div class="text-center" v-else-if="radioGroupOption == 'status'">
                        <v-radio-group v-model="status">
                            <v-radio v-for="item in statusOption" :key="item" :label="item" :value="item"></v-radio>
                        </v-radio-group>
                    </div>
                    <v-card-actions class="mt-2" justify="end">
                        <v-btn color="primary" plain @click="submitFilter">Submit</v-btn>
                        <v-btn color="red" plain @click="isFilterOn = false">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import PackModal from './PackModal.vue';
import axios from 'axios';
import moment from "moment-timezone";
import lookup from "country-code-lookup";
import { Country, State, City } from 'country-state-city';
export default {
    components: {
        PackModal
    },
    data() {
        return {
            ENDPOINT: process.env.VUE_APP_ENDPOINT,
            orders: [], // Fetch orders from your API
            showPackModal: false,
            selectedOrder: null,
            loading: false,
            totalOrders: 0,
            options: {},
            searchQuery: '',
            gift: false,
            giftMessage: false,
            customMessage: "",
            expressDelivery: false,
            headers: [
                { text: 'Order ID', value: 'id' },
                { text: 'Customer Name', value: 'billing' },
                { text: 'Items', value: 'line_items' },
                { text: 'Count', value: 'count' },
                // { text: 'Payment Method', value: 'payment_method_title' },
                { text: 'Date', value: 'date_created' },
                { text: 'Status', value: 'status' },
                { text: 'Actions', value: 'action', sortable: false }
            ],
            modalOrder: {},
            date: '',
            status: '',
            statusOption: ['processing', 'ready', 'shipped', 'packed', 'failed', 'pending'],
            isFilterOn: false,
            radioGroupOption: '',
            radiovalue: ['date', 'status'],
            showingFR: false,
            isDialogOpen: false,
            isDialogTwoOpen: false,
            totalPages: 1,
        };
    },
    mounted() {
        this.fetchOrders();
        this.resetFlags();
    },
    watch: {
        options: {
            handler() {
                this.fetchOrders();
            },
            deep: true,
        },
    },
    methods: {
        resetFlags() {
            console.log("resetFlags fired"); // Debugging log
            this.gift = false;
            this.giftMessage = false;
            this.customMessage = "";
            this.expressDelivery = false;
        },
        async submitFilter() {
            if (this.radioGroupOption == 'date') {
                console.log("date: ", this.date); //date format YYYY-MM-DD
                this.loading = true;

                try {

                    const { page, itemsPerPage } = this.options;


                    const response = await axios.get(this.ENDPOINT + `/orders?search=${this.searchQuery}&page=${page}&per_page=${itemsPerPage}&date=${this.date}`);
                    this.orders = response.data.orders;

                    console.log(this.orders)

                    this.totalOrders = Number(response.data.totalOrders);
                    this.totalPages = Number(response.data.totalPages);
                    console.log("purhcase total orders: ", this.totalOrders); // Update this line
                    // this.totalPages = response.data.totalPages; // Add this line

                } catch (error) {
                    console.error('Error fetching orders:', error);
                } finally {
                    this.loading = false;
                }

            } else {
                console.log("status: ", this.status);
                this.loading = true;

                try {

                    const { page, itemsPerPage } = this.options;


                    const response = await axios.get(this.ENDPOINT + `/orders?search=${this.searchQuery}&page=${page}&per_page=${itemsPerPage}&status=${this.status}`);
                    this.orders = response.data.orders;

                    console.log(this.orders)

                    this.totalOrders = Number(response.data.totalOrders);
                    this.totalPages = Number(response.data.totalPages);
                    console.log("purhcase total orders: ", this.totalOrders); // Update this line
                    // this.totalPages = response.data.totalPages; // Add this line

                } catch (error) {
                    console.error('Error fetching orders:', error);
                } finally {
                    this.loading = false;
                }
            }
            this.showingFR = true;
            this.isFilterOn = false;
        },
        removeFilter() {
            this.showingFR = false;
            this.fetchOrders();
        },
        printLabelTwo() {
            const hiddenImg = new Image();

            // Set the src attribute to the path of your image in the assets folder
            hiddenImg.src = require('@/assets/Label.png');

            // Hide the img element with CSS
            hiddenImg.style.display = 'none';

            // Append the img element to the body
            document.body.appendChild(hiddenImg);

            // Open a new window
            const printWindow = window.open('', '_blank');

            // Create a new HTML document in the new window
            printWindow.document.open();
            printWindow.document.write('<html><head><title>Print</title></head><body>');

            // Append the image element to the new document
            printWindow.document.write('<img id="printImage" src="' + hiddenImg.src + '" alt="Printable Image">');

            // Close the HTML document
            printWindow.document.write('</body></html>');
            printWindow.document.close();

            // Wait for both the image and the window to load before triggering the print dialog
            hiddenImg.onload = function () {
                printWindow.onload = function () {
                    // Trigger the print dialog
                    printWindow.print();
                    printWindow.close();
                };
            };
        },
        printLabel(order) {
            console.log("Order: ", order);
            const countryLookup = lookup.byIso(order.shipping.country);
            console.log("country name from lookup: ", countryLookup.country);
            const stateInfo = order.shipping.country ? (order.shipping.state != 'TS' ? State.getStateByCodeAndCountry(order.shipping.state, order.shipping.country) : State.getStateByCodeAndCountry('TG', order.shipping.country)) : {};
            // const res = order.get_billing();
            // console.log("get billing function: ", res);
            console.log("state info: ", stateInfo);
            console.log("order.country")
            const labelContent = `
            <html>
            <head>
                <title>Label for Order ${order.id}</title>
                <style>
                    body { font-family: Arial, sans-serif; }
                    .label { margin: 20px; font-size: 18px; }
                    .label-box { border: 1px solid black; padding: 10px; }
                    .label-section { margin-bottom: 20px; }
                    .flex-container { display: flex; justify-content: space-between; flex-direction: column; }
                    .flex-item { flex-basis: 45%; }
                    .text-alignment { text-align: right; }
                </style>
            </head>
            <body>
                <div class="label">
                    <div class="flex-container">
                        <!-- To Section -->
                        <div class="flex-item label-box">
                            <strong>Order ID:</strong> ${order.id}<br>
                            <strong>Customer Name:</strong> ${order.shipping.first_name} ${order.shipping.last_name}<br>
                            <strong>Address:</strong> ${order.shipping.address_1}, ${order.shipping.address_2 ? `${order.shipping.address_2},` : ''} ${order.shipping.city}, ${order.shipping.postcode},<br>
                            ${countryLookup.country == 'India' ? (stateInfo ? `${stateInfo.name}<br>` : '') : ''}
                            ${countryLookup.country}<br>
                            <strong>Phone:</strong> ${order.shipping.phone ? order.shipping.phone : order.billing.phone}<br>
                            <strong>Email:</strong> ${order.shipping.email ? order.shipping.email : order.billing.email}<br>
                            <strong>Payment Method:</strong> ${order.payment_method_title}<br>
                            ${order.payment_method == "cod" ? `<strong>Total Amount:</strong> ${order.total}<br>` : ''}
                            ${order.shipping_lines[0] && order.shipping_lines[0].method_title == "Express Shipping" ? `<strong>Delivery: </strong> Express Delivery<br>` : ''}
                            <strong>Date:</strong> ${moment(order.date_created).format('DD-MM-YYYY HH:mm')}<br>
                        </div>

                        <!-- From Section -->
                        <div class="flex-item label-box text-alignment">
                            <strong>From:</strong><br>
                            House Of AV<br>
                            Unit 17, Floor 17, Aurora Waterfront<br>
                            Sector 5, Salt Lake, Opp Nalban Food Park, West Bengal<br>
                            Kolkata 700091<br>
                            India<br>
                            7278027780<br>
                        </div>
                    </div>
                </div>
            </body>
            </html>
        `;

            const printWindow = window.open('', '_blank');
            printWindow.document.write(labelContent);
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        },
        async handleConfirmPacking(order) {
            this.isDialogOpen = true;
            // console.log("order: ", order);
            try {
                await axios.post(this.ENDPOINT + `/update-order-status`, {
                    orderId: order.id,
                    status: 'packed'
                });

                const response = await axios.post(this.ENDPOINT + `/send-email?status=packed`, {
                    orderId: order.id,
                    trackingId: '',
                    first_name: order.shipping.first_name,
                    email: order.shipping.email ? order.shipping.email : order.billing.email
                });

                const whatsappResponse = await axios.post(this.ENDPOINT + `/send-whatsapp?status=packed`, {
                    ...order,
                    trackingId: ''
                });
                this.$swal({
                    title: "Updated",
                    text: "Updated tracking info sent to registered email.",
                    icon: "success"
                })

                // alert("Success")
                this.fetchOrders();
                this.isDialogOpen = false;
                this.resetFlags();
            } catch (error) {
                console.error('Error updating order status:', error);
                this.isDialogOpen = false;
                this.gift = false;
                this.giftMessage = false;
                this.expressDelivery = false;
                // Handle error - e.g., show an error message
                this.$swal({
                    title: "Error",
                    text: "An Error occurred! Please try again.",
                    icon: "error"
                });
                // alert("Error")
            }
            // this.isDialogOpen = false;
        },
        async handleStatus(order, status) {
            if (status == "processing") {
                this.isDialogTwoOpen = true;
            } else {

                this.isDialogOpen = true;
            }
            try {
                await axios.post(this.ENDPOINT + `/update-order-status`, {
                    orderId: order.id,
                    status: status
                });
                console.log("Status: ", status);
                if (status == "ready" || status == "completed") {
                    const response = await axios.post(this.ENDPOINT + `/send-email?status=${status}`, {
                        orderId: order.id,
                        trackingId: '',
                        first_name: order.shipping.first_name,
                        email: order.shipping.email ? order.shipping.email : order.billing.email
                    });
                    const whatsappResponse = await axios.post(this.ENDPOINT + `/send-whatsapp?status=${status}`, {
                        ...order,
                        trackingId: ''
                    });
                    console.log("whatsappResponse: ", whatsappResponse.data);
                    console.log("response: ", response.data);
                    this.$swal({
                        title: "Success",
                        text: "Updated tracking info sent to registered email and whatsapp.",
                        icon: "success"
                    });
                    this.fetchOrders();
                    this.isDialogOpen = false;
                } else {
                    // alert("Success")
                    this.$swal({
                        title: "Updated",
                        text: "Tracking Information successfully updated.",
                        icon: "success"
                    })
                    this.fetchOrders();
                    this.isDialogTwoOpen = false;
                    this.isDialogOpen = false;
                }

                // console.log("response", response.data);

            } catch (error) {
                console.error('Error updating order status:', error);
                // Handle error - e.g., show an error message
                this.isDialogOpen = true;
                this.$swal({
                    title: "Error",
                    text: "An Error occurred! Please try again.",
                    icon: "error"
                });
            }
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        statusColor(status) {
            switch (status) {
                case 'processing': return 'blue';
                case 'ready': return 'green';
                case 'packed': return 'teal darken-1';
                case 'shipped': return 'orange accent-4';
                case 'failed': return 'red accent-4';
                case 'pending': return 'deep-purple lighten-3';
                case 'completed': return 'cyan darken-4';
                // Add other cases as needed
                default: return 'lightgray';
            }
        },
        formatCustomerName(billing) {
            return `${billing.first_name} ${billing.last_name}`;
        },
        formatLineItems(lineItems) {
            return lineItems.map(item => item.name).join(', ');
        },
        openPackModal(order) {
            console.log("openPackModal invoked");
            console.log("Before resetFlags");
            this.resetFlags();
            console.log("After resetFlags");
            const packModal = this.$refs.packModal;
            this.modalOrder = order;

            const containsGiftWrapping = this.modalOrder.fee_lines.some(item => item.name === "Gift Wrapping");
            const containsMessage = this.modalOrder.fee_lines.some(item => item.name === "Message");

            if (containsGiftWrapping) {
                this.gift = true;
                console.log("Gift wrap: ", this.gift);
            }

            if (containsMessage) {
                this.giftMessage = true;
                // console.log("Gift wrap: ", this.gift);
                const customMessageObject = this.modalOrder.meta_data.find(item => item.key === "Custom Message");
                this.customMessage = customMessageObject.value;
            }



            if (order.shipping_lines[0] && order.shipping_lines[0].method_title == "Express Shipping") {
                this.expressDelivery = true;
                console.log("this.expressDelivery", this.expressDelivery);
            }
            packModal.order = order;
            packModal.visible = true;
        },
        closeModal() {
            // this.showPackModal = false;
            const packModal = this.$refs.packModal
            packModal.visible = false;
            this.resetFlags();
        },
        searchFunc() {
            this.options.page = 1;
            this.fetchOrders();
        },
        clearSearch() {
            this.searchQuery = "";
            this.fetchOrders();
        },
        async fetchOrders() {
            this.loading = true;

            try {

                const { page, itemsPerPage } = this.options;


                const response = await axios.get(this.ENDPOINT + `/orders?search=${this.searchQuery}&page=${page}&per_page=${itemsPerPage}`);
                this.orders = response.data.orders;

                console.log(this.orders)

                this.totalOrders = Number(response.data.totalOrders);
                this.totalPages = Number(response.data.totalPages);
                console.log("purhcase total orders: ", this.totalOrders); // Update this line
                // this.totalPages = response.data.totalPages; // Add this line

            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
<style scoped>
.main-container {
    max-width: 95vw;
}

.v-data-table>.v-data-table__wrapper>table {
    border-spacing: 0 0.5rem;
}

.custom-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 31em) {
    .custom-actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: end;
        margin-bottom: 1rem;
    }
}
</style>