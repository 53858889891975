<template>
    <v-container fluid class="px-6">
        <!-- ? Orders ready to pick up list -->

        <div class="d-flex flex-wrap align-center justify-space-between my-3">

            <p class="text-h5 ma-0 my-4 font-weight-black">Orders Ready to pickup</p>
            <div class="custom-search">

                <v-text-field class="custom-search" v-model="searchReady" append-icon="mdi-magnify"
                    label="Search by name" single-line hide-details @input="updateSearch"></v-text-field>
            </div>

        </div>
        <v-row v-if="loadingTwo == false && readyOrders.length">

            <v-col v-for="item in displayedItems" :key="item.id" cols="6" lg="2" md="4">
                <v-card class="pa-4 fill-height custom-hover" @click="handleShipping(item)">
                    <div class="d-flex align-space-between justify-space-between flex-column fill-height">
                        <div>
                            <div class="d-flex align-center justify-space-between mb-4">
                                <h3>{{ item.id }}</h3>
                                <p class="ma-0">{{ customDate(item.date_created) }}</p>
                            </div>
                            <!-- <h3>Order id: </h3> -->
                            <p class="ma-0"><span class="font-weight-black">Name:</span> {{ item.shipping.first_name }}
                                {{ item.shipping.last_name }}</p>
                            <!-- <p class="ma-0">Order Date: {{ customDate(item.date_created) }}</p> -->
                            <!-- <p class="ma-0">Amount: {{ item.total }}</p> -->
                            <p class="ma-0"><span class="font-weight-black">Mode:</span> {{ item.payment_method }}</p>
                            <!-- <p class="ma-0">Items Count: {{ item.line_items.length }}</p> -->
                        </div>

                        <v-chip class="" color="green" text-color="white">
                            <v-avatar left class="green darken-4">
                                {{ item.line_items.length }}
                            </v-avatar>
                            Items
                        </v-chip>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <div class="d-flex align-center justify-end my-3" v-if="loadingTwo == false && readyOrders.length">
            <v-pagination v-model="currentPage" :length="totalPages" prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"></v-pagination>
        </div>
        <v-row v-if="loadingTwo == false && !readyOrders.length">
            <v-col>
                <v-card class="py-4 text-h5 font-weight-black">
                    <div class="d-flex align-center justify-center">
                        <v-icon class="mx-2 package" color="#5C6BC0">mdi-package</v-icon>
                        <p class="ma-0">No pending orders to pick up</p>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="loadingTwo == true && !readyOrders.length">
            <v-col>
                <v-card class="py-4 text-h5 font-weight-black">
                    <div class="d-flex align-center justify-center">
                        <v-progress-circular :size="30" color="purple" class="mx-3" indeterminate></v-progress-circular>
                        <p class="ma-0">Loading orders....</p>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <!-- ? Orders already shipped list -->

        <div class="d-flex flex-wrap align-center justify-space-between my-3">
            <p class="text-h5 ma-0 my-4 font-weight-black">Shipment Overview</p>
            <div class="d-flex align-center justify-space-between">
                <v-btn v-if="showingFR" class="mx-2" @click="exportToCSV">Export</v-btn>
                <v-btn v-if="!showingFR" @click="filterDialogOpen = true"><v-icon>mdi-filter</v-icon> Filter</v-btn>
                <v-btn v-else @click="removeFilter"><v-icon>mdi-close</v-icon> Remove Filter</v-btn>
            </div>
        </div>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" label="Search with order id" single-line
                            hide-details></v-text-field>
                        <v-btn class="mx-2" @click="getFilteredShippingOrders">Search</v-btn>
                        <v-btn class="mx-2" @click="clearSearch">Clear</v-btn>

                    </v-card-title>
                    <v-data-table :headers="headers" :items="shippingOrders" class="elevation-1"
                        :server-items-length="totalShippingOrders" :options.sync="options" :loading="loading"
                        :search="search" hide-default-footer></v-data-table>
                </v-card>
                <div class="d-flex align-center justify-end my-3" v-if="loading == false && shippingOrders.length">
                    <v-pagination v-model="options.page" :length="totalPagesShip" :options.sync="options"
                        :total-visible="5"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <!-- ? Dialog to add shipping details -->

        <v-dialog v-if="order" v-model="isDialogOpen" persistent max-width="500">
            <v-card class="pa-4">
                <v-text-field v-model="order.id" label="Order Id" disabled></v-text-field>
                <v-text-field v-model="trackingId" label="Tracking ID"></v-text-field>
                <v-select :items="['dtdc', 'indiapost', 'delhivery', 'other']" v-model="shippingCompany"
                    label="Shipping Company" @change="checkOtherCompany"></v-select>
                <v-text-field v-if="isOtherCompany" v-model="otherShippingCompany"
                    label="Other Shipping Company"></v-text-field>
                <v-text-field v-model="shippingCost" label="Cost" type="number"></v-text-field>
                <v-text-field v-model="trackingLink" label="Tracking Link" type="text"></v-text-field>
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="shippingDate" label="Shipping Date" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="shippingDate" @input="menu2 = false"></v-date-picker>
                </v-menu>
                <v-card-actions class="pa-0">
                    <v-btn @click="updateShippingInfo" color="success">Update Shipping Info</v-btn>
                    <v-btn @click="closeOrder">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- ? Dialog to select date range -->

        <v-dialog v-model="filterDialogOpen" persistent width="auto" max-width="500">
            <v-card class=" d-flex align-center justify-center flex-column pa-4">

                <v-date-picker v-model="dateRange" range @input="handleDate"></v-date-picker>
                <div class="d-flex align-center justify-center mt-3">
                    <v-btn @click="handleFilter" class="mx-4" color="success">Continue</v-btn>
                    <v-btn @click="filterDialogOpen = false">Cancel</v-btn>
                </div>

            </v-card>
        </v-dialog>

        <!-- Processing pop-up  -->
        <v-dialog v-model="isDialogTwoOpen" persistent max-width="450">
            <v-card class="d-flex align-center justify-center pa-7">

                <v-progress-circular :size="50" color="purple" indeterminate></v-progress-circular>
                <v-card-title class="text-h6">Processing...</v-card-title>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from "moment-timezone"
import Papa from 'papaparse';
export default {
    data() {
        return {
            ENDPOINT: process.env.VUE_APP_ENDPOINT,
            orderId: '',
            order: null,
            trackingId: '',
            shippingCompany: '',
            otherShippingCompany: '',
            shippingCost: 0,
            shippingDate: '',
            isOtherCompany: false,
            snackbar: false,
            timeout: 2000,
            snackbarText: '',
            trackingLink: '',
            linkArray: [
                {
                    trackingCompany: 'dtdc',
                    link: 'https://www.dtdc.in/tracking.asp'
                },
                {
                    trackingCompany: 'delhivery',
                    link: 'https://www.delhivery.com/tracking'
                },
                {
                    trackingCompany: 'bluedart',
                    link: 'https://www.bluedart.com/tracking'
                },
            ],
            trackMessage: '',
            isDialogOpen: false,
            isDialogTwoOpen: false,
            search: '',
            searchReady: "",
            loading: false,
            loadingTwo: false,
            options: {},
            totalShippingOrders: 0,
            totalReadyOrders: 0,
            shippingOrders: [],
            readyOrders: [],
            filterReady: [],
            currentPage: 1,
            itemsPerPage: 12,
            startDate: "",
            endDate: "",
            headers: [
                { text: 'Order Id', value: 'order_id' },
                { text: 'Order Date', value: 'order_date' },
                { text: 'Payment Mode', value: 'payment_mode' },
                { text: 'Shipping Cost', value: 'shipping_cost' },
                { text: 'Shipping Date', value: 'shipping_date' },
                { text: 'COD Amount', value: 'cod_amount' },
                { text: 'Shipping Company', value: 'shipping_company' },
                { text: 'Tracking Id', value: 'tracking_id' },

            ],
            shipItemsPerPage: 10,
            showingFR: false,
            filterShipping: [],
            itemsPerPageShip: 10,
            currentPageShip: 1,
            filterDialogOpen: false,
            dateRange: [],
            menu2: false,
            totalPagesShip: 0,
        };
    },
    mounted() {
        this.getFilteredReadyOrders();
        // this.getFilteredShippingOrders()
    },
    computed: {
        totalPages() {
            return Math.ceil(this.filterReady.length / this.itemsPerPage);
        },
        // totalPagesShip() {
        //     return Math.ceil(this.totalShippingOrders.length / this.options.itemsPerPage);
        // },
        // displayedItemsShip() {
        //     if (this.search.trim() === '') {
        //         // If no search query, return all items
        //         this.filterShipping = this.shippingOrders;
        //         // const start = (this.currentPageShip - 1) * this.itemsPerPage;
        //         // const end = start + this.itemsPerPage;
        //         return this.filterShipping;
        //     } else {
        //         this.filterShipping = this.shippingOrders.filter(item => {
        //             return item.order_id.toString().includes(this.search);
        //         });
        //         // console.log("filtered array: ", this.filterShipping);
        //         // const start = (this.currentPage - 1) * this.itemsPerPage;
        //         // const end = start + this.itemsPerPage;
        //         return this.filterShipping;
        //     }
        //     // else {
        //     //     const sortedDateRange = this.dateRange.map(dateString => new Date(dateString)).sort((a, b) => a - b);

        //     //     const startDate = sortedDateRange[0];
        //     //     const endDate = sortedDateRange[1];

        //     //     this.filterShipping = this.shippingOrders.filter(item => {
        //     //         const shippingDate = new Date(item.shipping_date);
        //     //         return shippingDate >= startDate && shippingDate <= endDate;
        //     //     });
        //     //     return this.filterShipping;
        //     // }

        // },
        displayedItems() {
            if (this.searchReady.trim() === '') {
                // If no search query, return all items
                this.filterReady = this.readyOrders;
                const start = (this.currentPage - 1) * this.itemsPerPage;
                const end = start + this.itemsPerPage;
                return this.readyOrders.slice(start, end);
            } else {
                this.filterReady = this.readyOrders.filter(item => {
                    const firstName = String(item.shipping.first_name || '').toLowerCase();
                    const lastName = String(item.shipping.last_name || '').toLowerCase();
                    const searchReadyLower = this.searchReady.toLowerCase();
                    return (
                        item.id.toString().includes(searchReadyLower) ||
                        firstName.includes(searchReadyLower) ||
                        lastName.includes(searchReadyLower)
                    )
                    // return item.id.toString().includes(this.searchReady);
                });
                console.log("filtered array: ", this.filterReady);
                const start = (this.currentPage - 1) * this.itemsPerPage;
                const end = start + this.itemsPerPage;
                return this.filterReady.slice(start, end);
            }

        },

    },
    watch: {
        options: {
            handler() {
                this.getFilteredShippingOrders();
            },
            deep: true,
        },
        searchReady() {
            this.currentPage = 1; // Reset to the first page when search changes
        },
    },
    methods: {
        customDate(date) {
            return moment(date).format("DD-MM-YYYY");
        },
        handleFilter() {
            console.log("Date range array: ", this.dateRange);
            const sortedDateRange = this.dateRange.map(dateString => new Date(dateString)).sort((a, b) => a - b);
            this.startDate = moment(sortedDateRange[0]).format("YYYY-MM-DD");
            this.endDate = moment(sortedDateRange[1]).format("YYYY-MM-DD");
            console.log("Start Date: ", this.startDate, "End Date: ", this.endDate);
            this.getFilteredShippingOrders();
            this.filterDialogOpen = false;
            this.showingFR = true;
        },
        removeFilter() {
            this.dateRange = [];
            this.showingFR = false;
            this.startDate = "";
            this.endDate = "";
            this.getFilteredShippingOrders();
        },
        handleDate() {
            console.log("Date range: ", this.dateRange);
        },
        updateSearch() {
            this.currentPage = 1; // Reset to the first page when search changes
            // if (this.searchReady.length) {
            //     const results = this.readyOrders.filter(item => {
            //         // Access the 'shipping' object and its 'first_name' and 'last_name' fields
            //         const shipping = item.shipping || {};
            //         const firstName = String(shipping.first_name || '').toLowerCase();
            //         const lastName = String(shipping.last_name || '').toLowerCase();

            //         // Check if the search term is present in 'first_name' or 'last_name'
            //         return firstName.includes(searchTerm) || lastName.includes(searchTerm);
            //     });
            // } else {
            //     this.getFilteredReadyOrders()
            // }

        },
        updateSearchShip() {
            this.currentPageShip = 1
        },
        handleShipping(order) {
            this.order = order;
            this.isDialogOpen = true;
        },
        closeOrder() {
            this.isDialogOpen = false;
            this.order = null;
            this.shippingCompany = "";
            this.trackingLink = "";
            this.trackingId = "";
            this.shippingCost = 0;
            this.shippingDate = "";
        },
        async getFilteredReadyOrders() {
            this.loadingTwo = true;
            try {
                const start = this.startDate != "" ? this.startDate : "blank";
                const end = this.endDate != "" ? this.endDate : "blank";
                // const stat = this.status.length ? this.status : "shipped";
                const stat = ['ready']
                const payment = "any";
                const { page, itemsPerPage } = this.options;
                const url = this.ENDPOINT + `/cus-reports?search=${this.searchReady}&page=1&per_page=10&startDate=${start}&endDate=${end}&status=${stat}&payment=${payment}`;
                const response = await axios.get(url);
                this.totalReadyOrders = Number(response.data.totalOrders);
                console.log("total ready orders: ", this.totalReadyOrders);
                if (this.totalReadyOrders > 0) {
                    const res = await axios.get(this.ENDPOINT + `/cus-reports?search=${this.searchReady}&page=1&per_page=${this.totalReadyOrders}&startDate=${start}&endDate=${end}&status=${stat}&payment=${payment}`)
                    this.readyOrders = res.data.orders;
                    console.log("ready orders: ", this.readyOrders);
                }

            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                this.loadingTwo = false;
            }
        },
        clearSearch() {
            this.search = "";
            this.getFilteredShippingOrders();
        },
        async getFilteredShippingOrders() {
            this.loading = true;

            try {

                const start = this.startDate != "" ? this.startDate : "blank";
                const end = this.endDate != "" ? this.endDate : "blank";
                const stat = ['shipped', "completed"]
                const payment = "any";
                const { page, itemsPerPage } = this.options;
                const url = this.ENDPOINT + `/cus-reports?search=${this.search}&page=${page}&per_page=${itemsPerPage}&startDate=${start}&endDate=${end}&status=${stat}&payment=${payment}`;


                const response = await axios.get(url);
                this.totalShippingOrders = Number(response.data.totalOrders);
                this.totalPagesShip = Number(response.data.totalPages)
                console.log("Total Shipping orders: ",)
                this.shippingOrders = this.processOrders(response.data.orders);
                // if (this.totalReadyOrders > 0) {
                //     const res = await axios.get(this.ENDPOINT + `/reports?search=${this.search}&page=1&per_page=${this.totalShippingOrders}&startDate=${start}&endDate=${end}&status=${stat}&payment=${payment}`)
                //     this.shippingOrders = this.processOrders(res.data.orders);
                //     console.log("Final shipped orders: ", this.shippingOrders);
                // }
                // this.shippingOrders = [
                //     {
                //         order_id: 1,
                //         shipping_company: "abcd",
                //         tracking_id: "1234l",
                //         shipping_cost: 120,
                //         item_count: 4,
                //         shipping_date: "2023-12-01",
                //         payment_mode: "phonepe",
                //         cod_amount: 1299
                //     },
                //     {
                //         order_id: 2,
                //         shipping_company: "abcd",
                //         tracking_id: "1234l",
                //         shipping_cost: 120,
                //         item_count: 4,
                //         shipping_date: "2023-12-02",
                //         payment_mode: "phonepe",
                //         cod_amount: 1299
                //     },
                //     {
                //         order_id: 3,
                //         shipping_company: "abcd",
                //         tracking_id: "1234l",
                //         shipping_cost: 120,
                //         item_count: 4,
                //         shipping_date: "2023-12-10",
                //         payment_mode: "phonepe",
                //         cod_amount: 1299
                //     },
                // ]

            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                this.loading = false;
            }
        },

        checkOtherCompany() {
            this.isOtherCompany = this.shippingCompany === 'other';
            const companyObject = this.linkArray.find(item => item.trackingCompany === this.shippingCompany);
            if (companyObject) {
                this.trackingLink = companyObject.link;
            } else {
                this.trackingLink = '';
            }
        },
        async updateShippingInfo() {
            if (this.trackingId == '' || this.shippingCompany == '' || this.shippingDate == '' || this.trackingLink == '') {
                this.$swal({
                    title: "Please Fill out the blank fields",
                    text: "Please fill out all the fields in order to continue.",
                    icon: "Error"
                });

            } else {
                this.isDialogTwoOpen = true;
                try {
                    const response = await axios.post(this.ENDPOINT + `/orders/${this.order.id}/update-shipping`, {
                        trackingId: this.trackingId,
                        shippingCompany: this.isOtherCompany ? this.otherShippingCompany : this.shippingCompany,
                        shippingCost: this.shippingCost,
                        shippingDate: this.shippingDate,
                        trackingLink: this.trackingLink
                    });
                    console.log("Response of shipping: ", response.data);
                    await this.sendEmailNM();
                    this.searchReady = "";
                    this.readyOrders = [];
                    this.getFilteredReadyOrders();
                    this.getFilteredShippingOrders();
                    // alert("success");
                    this.isDialogTwoOpen = false;
                    this.isDialogOpen = false;
                    this.$swal({
                        title: "Updated",
                        text: "Tracking Information successfully updated.",
                        icon: "success"
                    });
                    this.closeOrder();

                    // this.fetchOrder();
                    // Handle success - e.g., show a success message or refresh the page
                } catch (error) {
                    console.log('Error updating shipping info:', error);
                    this.isDialogOpen = false;
                    this.isDialogTwoOpen = false;
                    // Handle error
                    this.$swal({
                        title: "Error",
                        text: "An error occured! Please try again",
                        icon: "Error"
                    });
                    this.closeOrder();
                }
            }

        },

        async sendEmailNM() {
            const response = await axios.post(this.ENDPOINT + `/send-email?status=shipped`, {
                orderId: this.order.id,
                trackingId: this.trackingId,
                first_name: this.order.shipping.first_name,
                email: this.order.shipping.email ? this.order.shipping.email : this.order.billing.email
            });
            const whatsappResponse = await axios.post(this.ENDPOINT + `/send-whatsapp?status=shipped`, {
                ...this.order,
                trackingId: this.trackingId
            });
            // console.log("response", response.data);
            console.log("whatsappResponse", whatsappResponse.data);
        },
        async sendWhatsapp() {
            var recipient = this.order.shipping.phone ? this.order.shipping.phone : this.order.billing.phone;
            const link = `https://api.whatsapp.com/send?phone=${recipient}`;
            window.open(link, '_blank');
        },
        processOrders(orders) {
            console.log("Full Order Details: ", orders);
            return orders.map(order => ({
                order_id: order.id,
                order_date: moment(order.date_created).format('YYYY-MM-DD'),
                shipping_company: order.meta_data.find(meta => meta.key === 'shipping_company')?.value || '',
                tracking_id: order.meta_data.find(meta => meta.key === 'tracking_id')?.value || '',
                shipping_cost: order.meta_data.find(meta => meta.key === 'shipping_cost')?.value || '',
                shipping_date: order.meta_data.find(meta => meta.key === 'shipping_date')?.value || '',
                item_count: order.line_items.length,
                payment_mode: order.payment_method_title ? order.payment_method_title : "",
                cod_amount: order.payment_method_title == "Cash on delivery" ? order.total : "-",
            }));
        },
        async exportToCSV() {
            this.isDialogTwoOpen = true;
            const start = this.startDate != "" ? this.startDate : "blank";
            const end = this.endDate != "" ? this.endDate : "blank";
            const stat = ["shipped", "completed"];
            const payment = "any";
            const { page, itemsPerPage } = this.options;
            const url = this.ENDPOINT + `/reports?search=${this.search}&page=${page}&per_page=${this.totalShippingOrders}&startDate=${start}&endDate=${end}&status=${stat}&payment=${payment}`;
            console.log("url :", url);

            try {
                const response = await axios.get(url);
                const customOrders = this.processOrders(response.data.orders);
                console.log("Custom Orders: ", customOrders);
                const headers = [
                    'Order ID',
                    'Order Date',
                    'Payment Mode',
                    'Shipping Cost',
                    'Shipping Date',
                    'COD Amount',
                    'Shipped Company',
                    'Tracking Id',
                ];


                const csvData = Papa.unparse({
                    fields: headers,
                    data: customOrders.map(order => { // Map each order to an array of cell values
                        return [
                            order.order_id, order.order_date, order.payment_mode, order.shipping_cost, order.shipping_date, order.cod_amount, order.shipping_company, order.tracking_id
                        ];
                    }),
                });
                const filename = 'orders.csv';

                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = filename;
                anchor.click();
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
            this.isDialogTwoOpen = false;
        },
    },
};
</script>
<style scoped>
.fill-height {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.custom-search {
    width: 30vw !important;
    padding-top: 0;
    margin-top: 0;
}

.package {
    font-size: 2rem;
}

.custom-hover:hover {
    background-color: #5C6BC0;
    color: white;
    cursor: pointer;
}

.chip-red {
    color: white;
}

.order-status {
    display: flex;
    align-items: center;
}

.order-status p {
    margin: 0;
    margin-right: 0.5rem;
    font-size: 1.3rem;
    font-weight: 500;
}

.tracking-container {
    margin-top: 1rem;
    border: 1px solid black;
    border-radius: 10px;
    padding: 1rem;
}

.send-button {
    color: white;
}

@media only screen and (max-width: 450px) {
    .custom-search {
        padding-top: 0;
        margin-top: 0;
        width: 100% !important;
    }
}
</style>