<template>
    <v-container fluid class="custom-container">
        <v-row class="d-flex align-center justify-center card-container">
            <v-card class="pa-5" width="40rem" max-width="90vw">
                <div class="top-header">
                    <v-icon class="custom-icon" color="white">mdi-account</v-icon>
                    <v-card-title class="text-center text-h3 my-4">Login</v-card-title>
                </div>

                <v-text-field v-model.trim="userId" label="User Id" outlined></v-text-field>
                <v-text-field v-model.trim="password" label="Password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" outlined></v-text-field>
                <v-btn color="indigo white--text" class="py-5" @click="handleLogin" block>Login</v-btn>
            </v-card>
        </v-row>
    </v-container>
</template>
  
<script>
import axios from "axios"
import { mapState } from 'vuex';

export default {
    data() {
        return {
            ENDPOINT: process.env.VUE_APP_ENDPOINT,
            userId: "",
            password: "",
            show1: false
        };
    },
    computed: {
        ...mapState(['user']),
    },
    // watch: {
    //     userId() {
    //         this.userId = this.userId.replace(/\s/g, "");
    //     },
    //     password() {
    //         this.password = this.password.replace(/\s/g, "");
    //     }
    // },
    methods: {
        async handleLogin() {
            // Check userID and password for spaces and remove tem before sending to backend
            this.userId = this.userId.replace(/\s/g, "");
            this.password = this.password.replace(/\s/g, "");
            try {
                const response = await axios.post(`${this.ENDPOINT}/login`, {

                    userId: this.userId,
                    password: this.password

                });
                console.log("backend response: ", response.data);
                await this.$store.dispatch('loginUser', response.data.user);
                if (this.user.role == "manager" || this.user.role == "agent") {

                    this.$router.push("/home");
                } else {
                    this.$router.push("/dashboard");
                }
                // await this.$store.dispatch('logout');
                // console.log("state: ", this.user);
            } catch (e) {
                console.log("error: ", e);
                this.$swal({
                    title: "Error",
                    text: "An Error occurred! Please try again.",
                    icon: "error"
                });

            }


        },
        // removeSpaces(event) {
        //     console.log("Event fired");
        //     const keyCode = event.key;
        //     console.log("keyCode", keyCode);
        //     if (keyCode === ' ') { // Check if the key pressed is the spacebar
        //         event.preventDefault();
        //         return;
        //     }
        //     this.userId = this.userId.replace(/\s/g, "");
        //     this.password = this.password.replace(/\s/g, "");
        // }

    }
};
</script>
<style scoped>
.custom-icon {
    font-size: 3rem;
    border: 3px solid #3f51b5;
    background-color: #3f51b5;
    border-radius: 50%;
    padding: 1rem;
}

.top-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-container {
    height: 100%;
}

.custom-container {
    height: 100vh;
}
</style>