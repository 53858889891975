<template>
    <v-container fluid class="px-6">
        <!-- Toggle between Purchase and Vendor -->
        <v-tabs v-model="selectedTab" class="my-4" centered>
            <v-tab href="#purchases">Purchases</v-tab>
            <v-tab href="#vendor">Vendors</v-tab>
        </v-tabs>
        <v-tabs-slider></v-tabs-slider>
        <!-- Purchase Section -->
        <v-tabs-items v-model="selectedTab">
            <v-tab-item value="purchases" class="pa-2">
                <v-btn color="primary" class="my-2" @click="openAddModal">Add Purchase</v-btn>

                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" label="Search with Purchase ID" single-line
                            hide-details></v-text-field>
                        <v-btn class="mx-2" @click="fetchPurchases">Search</v-btn>
                        <v-btn class="mx-2" @click="clearSearch">Clear</v-btn>
                    </v-card-title>

                    <v-data-table :headers="headers" :items="formattedPurchases" class="elevation-1"
                        :server-items-length="totalPurchases" :options.sync="options" :loading="loading"
                        hide-default-footer>
                        <template v-slot:item.purchaseId="{ item }">
                            <span>
                                {{ item.purchaseId }}
                                <v-btn icon @click="copyToClipboard(item.purchaseId)">
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn icon @click="editPurchase(item)">
                                <v-icon color="blue">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="deletePurchase(item.purchaseId)">
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>

                <div class="d-flex align-center justify-end my-3" v-if="!loading && purchases.length">
                    <v-pagination v-model="options.page" :length="totalPages" :total-visible="5"
                        @input="fetchPurchases"></v-pagination>
                </div>
            </v-tab-item>
            <v-tab-item value="vendor" class="pa-2">
                <v-btn color="primary" class="my-2" @click="openAddModalVendor">Add Vendor</v-btn>

                <v-card>
                    <v-data-table :headers="vendorHeaders" :items="vendors" class="elevation-1" hide-default-footer>
                        <template v-slot:item.action="{ item }">
                            <v-btn icon @click="editVendor(item)">
                                <v-icon color="blue">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="deleteVendor(item._id)">
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <!-- Modal Dialog for Adding/Editing Purchase -->
        <v-dialog v-model="showDialog" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h6">{{ isEditing ? 'Edit Purchase' : 'Add New Purchase' }}</span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-text-field v-if="isEditing" v-model="newPurchase.purchaseId" label="Purchase ID"
                            disabled></v-text-field>
                        <!-- <v-text-field v-model="newPurchase.vendorName" label="Vendor Name" required></v-text-field> -->
                        <v-select v-model="newPurchase.vendObj" :items="vendors" item-text="name" item-value="id"
                            label="Choose Vendor" return-object required></v-select>
                        <v-text-field v-model="newPurchase.amount" label="Amount" required></v-text-field>
                        <v-text-field v-model="newPurchase.transactionId" label="Transaction ID"
                            required></v-text-field>
                        <v-select v-model="newPurchase.items" :items="itemOptions" label="Items" multiple chips
                            required></v-select>
                        <v-menu v-model="invoiceMenu" :close-on-content-click="false" transition="scale-transition"
                            offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="newPurchase.invoiceDate" label="Invoice Date"
                                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                    required></v-text-field>
                            </template>
                            <v-date-picker v-model="newPurchase.invoiceDate"
                                @input="invoiceMenu = false"></v-date-picker>
                        </v-menu>
                        <v-text-field v-model="newPurchase.totalItems" label="Total Items" type="number"
                            required></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showDialog = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="isEditing ? updatePurchase() : addPurchase()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Modal Dialog for Adding/Editing Vendor -->
        <v-dialog v-model="showDialogVendor" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h6">{{ isEditingVendor ? 'Edit Vendor' : 'Add New Vendor' }}</span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-text-field v-model="newVendor.name" label="Vendor Name" required></v-text-field>
                        <v-text-field v-model="newVendor.phone" label="Contact Number" required></v-text-field>
                        <v-text-field v-model="newVendor.address" label="Address" required></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showDialogVendor = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text
                        @click="isEditingVendor ? updateVendor() : addVendor()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Popup to show purchaseId after saving -->
        <v-dialog v-model="showPurchaseIdDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">Purchase Created Successfully</v-card-title>
                <v-card-text>
                    <div class="d-flex align-center">
                        <span>Purchase ID: <strong>{{ purchaseId }}</strong></span>
                        <v-btn icon small @click="copyToClipboard(purchaseId)">
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showPurchaseIdDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" timeout="3000" bottom>
            {{ snackbarText }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            ENDPOINT: process.env.VUE_APP_ENDPOINT,
            showDialog: false,
            showDialogVendor: false,
            showPurchaseIdDialog: false,
            selectedTab: 'purchases', // Toggle between 'purchase' and 'vendor'
            purchases: [],
            vendors: [], // Vendors data
            snackbar: false,
            snackbarText: '',
            totalPurchases: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['createdOn'],
                sortDesc: [true],
            },
            loading: false,
            search: '',
            purchaseId: '',
            isEditing: false,
            isEditingVendor: false,
            itemOptions: [
                'vinyl', 'cd', 'cassette', 'bluray', 'dvd', 'vcd',
                'games', 'audio equipment', 'watches'
            ],
            headers: [
                { text: 'Purchase ID', value: 'purchaseId' },
                { text: 'Vendor Name', value: 'vendorName' },
                { text: 'Amount', value: 'amount' },
                { text: 'Invoice Date', value: 'invoiceDate' },
                { text: 'Total Items', value: 'totalItems' },
                { text: 'Transaction ID', value: 'transactionId' },
                { text: 'Actions', value: 'action', sortable: false },
            ],
            vendorHeaders: [
                { text: 'Vendor Name', value: 'name' },
                { text: 'Phone', value: 'phone' },
                { text: 'Address', value: 'address' },
                { text: 'Actions', value: 'action', sortable: false },
            ],
            invoiceMenu: false,
            newPurchase: {
                vendObj: {},
                purchaseId: '',
                amount: '',
                invoiceDate: '',
                totalItems: 0,
                transactionId: '',
                items: [],
            },
            newVendor: {
                name: '',
                phone: '',
                address: '',
            },
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalPurchases / this.options.itemsPerPage);
        },
        formattedPurchases() {
            return this.purchases.map((purchase) => ({
                ...purchase,
                invoiceDate: purchase.invoiceDate ? new Date(purchase.invoiceDate).toLocaleDateString('en-GB') : '',
            }));
        },
    },
    methods: {
        openAddModal() {
            this.isEditing = false;
            this.resetForm();
            this.showDialog = true;
        },
        openAddModalVendor() {
            this.isEditingVendor = false;
            this.resetVendorForm();
            this.showDialogVendor = true;
        },
        async fetchPurchases() {
            try {
                this.loading = true;
                const { page, itemsPerPage, sortBy, sortDesc } = this.options;

                const response = await axios.get(`${this.ENDPOINT}/purchases`, {
                    params: {
                        page,
                        limit: itemsPerPage,
                        sortBy: sortBy[0] || 'createdOn',
                        sortDesc: sortDesc[0] ? 'desc' : 'asc',
                        search: this.search,
                    },
                });

                const purchases = response.data.purchases;

                // Fetch vendors for each purchase and add it to the purchase object
                for (const purchase of purchases) {
                    if (purchase.vendorId) {
                        const vendorResponse = await axios.get(`${this.ENDPOINT}/vendors/${purchase.vendorId}`);
                        purchase.vendorName = vendorResponse.data.name; // Add vendor name to each purchase
                    } else {
                        purchase.vendorName = '';
                    }
                }

                this.purchases = purchases;
                this.totalPurchases = response.data.totalItems;
            } catch (error) {
                console.error('Error fetching purchases:', error);
            } finally {
                this.loading = false;
            }
        },
        clearSearch() {
            this.search = '';
            this.fetchPurchases();
        },
        async addPurchase() {
            try {
                console.log("got data: ", this.newPurchase)
                const purchaseData = {
                    vendorId: this.newPurchase.vendObj._id,
                    amount: this.newPurchase.amount,
                    invoiceDate: new Date(this.newPurchase.invoiceDate).toISOString(),
                    totalItems: this.newPurchase.totalItems,
                    transactionId: this.newPurchase.transactionId,
                    items: this.newPurchase.items,
                };
                const response = await axios.post(`${this.ENDPOINT}/purchases`, purchaseData);
                this.purchaseId = response.data.purchaseId;
                this.showPurchaseIdDialog = true;
                this.showDialog = false;
                this.fetchPurchases();
                this.resetForm();
            } catch (error) {
                console.error('Error adding purchase:', error);
            }
        },
        async editPurchase(item) {
            this.isEditing = true;

            // Fetch vendor if vendorId exists
            if (item.vendorId) {
                const response = await axios.get(`${this.ENDPOINT}/vendors/${item.vendorId}`);
                this.newPurchase = { vendObj: response.data, ...item };
            } else {
                this.newPurchase = { vendObj: {}, ...item };
            }

            console.log("typeof invoice: ", typeof this.newPurchase.invoiceDate);
            console.log("invoiceDate value: ", this.newPurchase.invoiceDate);

            // Use moment to parse the date in DD/MM/YYYY format and convert it to YYYY-MM-DD
            if (this.newPurchase.invoiceDate && typeof this.newPurchase.invoiceDate === 'string') {
                const parsedDate = moment(this.newPurchase.invoiceDate, 'DD/MM/YYYY');

                if (parsedDate.isValid()) {
                    this.newPurchase.invoiceDate = parsedDate.format('YYYY-MM-DD'); // Format the date to YYYY-MM-DD
                } else {
                    console.error('Invalid date value:', this.newPurchase.invoiceDate);
                    this.newPurchase.invoiceDate = ''; // Handle invalid date appropriately
                }
            }

            this.showDialog = true;
        },
        async getVendor(id) {

            const response = await axios.get(`${this.ENDPOINT}/vendors/${id}`);
            return response.data.name

        },
        async updatePurchase() {
            try {
                const purchaseData = {
                    vendorId: this.newPurchase.vendObj._id,
                    amount: this.newPurchase.amount,
                    invoiceDate: new Date(this.newPurchase.invoiceDate).toISOString(),
                    totalItems: this.newPurchase.totalItems,
                    transactionId: this.newPurchase.transactionId,
                    items: this.newPurchase.items,
                };
                await axios.put(`${this.ENDPOINT}/purchases/${this.newPurchase.purchaseId}`, purchaseData);
                this.showDialog = false;
                this.fetchPurchases();
                this.resetForm();
            } catch (error) {
                console.error('Error updating purchase:', error);
            }
        },
        async deletePurchase(id) {
            try {
                await axios.delete(`${this.ENDPOINT}/purchases/${id}`);
                this.fetchPurchases();
            } catch (error) {
                console.error('Error deleting purchase:', error);
            }
        },
        copyToClipboard(text) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    this.snackbarText = 'Purchase ID copied to clipboard!';
                    this.snackbar = true;
                })
                .catch((err) => {
                    console.error('Failed to copy:', err);
                    this.snackbarText = 'Failed to copy Purchase ID';
                    this.snackbar = true;
                });
        },
        async fetchVendors() {
            try {
                const response = await axios.get(`${this.ENDPOINT}/vendors`);
                this.vendors = response.data;
            } catch (error) {
                console.error('Error fetching vendors:', error);
            }
        },
        async addVendor() {
            try {
                const response = await axios.post(`${this.ENDPOINT}/vendors`, this.newVendor);
                this.fetchVendors();
                this.showDialogVendor = false;
                this.resetVendorForm();
            } catch (error) {
                console.error('Error adding vendor:', error);
            }
        },
        async editVendor(item) {
            this.isEditingVendor = true;
            this.newVendor = { ...item };
            this.showDialogVendor = true;
        },
        async updateVendor() {
            try {
                await axios.put(`${this.ENDPOINT}/vendors/${this.newVendor._id}`, this.newVendor);
                this.fetchVendors();
                this.showDialogVendor = false;
                this.resetVendorForm();
            } catch (error) {
                console.error('Error updating vendor:', error);
            }
        },
        async deleteVendor(id) {
            try {
                await axios.delete(`${this.ENDPOINT}/vendors/${id}`);
                this.fetchVendors();
            } catch (error) {
                console.error('Error deleting vendor:', error);
            }
        },
        resetForm() {
            this.newPurchase = {
                purchaseId: '',
                vendorName: '',
                amount: '',
                invoiceDate: '',
                totalItems: 0,
                transactionId: '',
                items: [],
            };
        },
        resetVendorForm() {
            this.newVendor = {
                vendorName: '',
                phone: '',
                address: '',
            };
        },
    },
    created() {
        this.fetchPurchases();
        this.fetchVendors();
    },
};
</script>

<style scoped>
/* Optional styling */
</style>